import React, { useEffect, useRef, FC, useMemo, useCallback, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { makeStyles } from '@material-ui/core'
import { CellValueChangedEvent, ColDef, GridOptions } from 'ag-grid-community'
import {
    getLocalStorageItem,
    getSessionStorageItem,
    setLocalStorageItem
} from 'businesslayer/minutesLocalStore'

interface AgGridComponentProps {
    gridId: string
    columns: ColDef[]
    data: any[]
    gridOptions?: GridOptions
    filterState?: any
    onCellValueChanged?: (event: CellValueChangedEvent<any, any>) => void
    pagination?: boolean
    triggerColumnResizing?: boolean
    OverlayNoRowsTemplate?: any
    onSortChanged?: any
    formData?: any
    gridClass?: string
}

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        height: '100%',
        overflow: 'auto'
    },
    gridContainer: {
        width: '100%',
        height: '100%',
        minHeight: '400px'
    },
    customHeader: {
        borderLeft: '1px solid #E6E6E6'
    }
}))

const AgGridComponent: FC<AgGridComponentProps> = React.memo(
    ({
        gridId, // Added gridId to props
        columns,
        data,
        gridOptions,
        filterState,
        onCellValueChanged,
        pagination = false,
        OverlayNoRowsTemplate,
        onSortChanged,
        formData,
        gridClass
    }) => {
        const apiRef = useRef<any>(null)
        const classes = useStyles()
        const containerRef = useRef<HTMLDivElement>(null)
        const [columnsData, setColumnsData] = useState<ColDef[]>(columns)

        useEffect(() => {
            setColumnsData(columns)
        }, [columns])

        useEffect(() => {
            if (filterState) {
                apiRef.current?.setFilterModel?.({
                    ...(apiRef.current?.getFilterModel() || {}),
                    ...filterState
                })
            }
        }, [filterState])

        const onColumnResized = (params) => {
            saveColumnState(params)
        }

        const setLocalStoredItem = (columnState) => {
            if (gridId === 'action-dashboard-grid') {
                setLocalStorageItem(
                    'columnState_action-dashboard-grid',
                    JSON.stringify(columnState)
                )
            } else if (gridId === 'minutes-actions') {
                setLocalStorageItem('columnState_minutes-actions', JSON.stringify(columnState))
            }
        }

        const saveColumnState = (params) => {
            const columnState = params.columnApi?.getColumnState()

            setLocalStoredItem(columnState)
        }

        const getLocalStoredItem = useCallback(() => {
            if (gridId === 'action-dashboard-grid') {
                return getLocalStorageItem('columnState_action-dashboard-grid')
            } else if (gridId === 'minutes-actions') {
                return getLocalStorageItem('columnState_minutes-actions')
            }

            return null
        }, [gridId])

        const restoreColumnState = useCallback(
            (params) => {
                let columnState = getLocalStoredItem()
                if (columnState && params.columnApi?.applyColumnState) {
                    let state = JSON.parse(columnState)
                    // Ensure columns in `columns` are visible by setting hide to false
                    const visibleColumnIds = columns
                        .filter((col) => col.field && col.hide === false)
                        .map((col) => col.field)

                    state = state.map((col) => ({
                        ...col,
                        hide: !visibleColumnIds.includes(col.colId)
                    }))
                    params.columnApi.applyColumnState({
                        state,
                        applyOrder: true
                    })
                }
            },
            [columns, getLocalStoredItem]
        )

        const onGridReady = useCallback(
            (params: any) => {
                apiRef.current = params.api
                // params.api.sizeColumnsToFit() //for future test
                const persistedState = getSessionStorageItem('persistedStateData')
                let columnState = getLocalStoredItem()

                if ((!persistedState || persistedState === '[]') && columnState) {
                    // If no session persisted state but there is a column state, restore from localStorage
                    restoreColumnState(params)
                    setColumnsData(columns)
                } else if (!persistedState || persistedState === '[]') {
                    // No persisted state found, so set default columns
                    setColumnsData(columns)
                } else {
                    // Restore column state if session storage data exists
                    restoreColumnState(params)
                }
            },
            [columns, restoreColumnState, getLocalStoredItem]
        )

        useEffect(() => {
            const observer = new ResizeObserver(() => {
                if (apiRef.current && (containerRef?.current?.clientWidth ?? 0) > 1400) {
                    apiRef.current.sizeColumnsToFit()
                }
            })
            if (containerRef.current) {
                observer.observe(containerRef.current)
            }
            return () => {
                observer.disconnect()
            }
        }, [])

        const memoizedData = useMemo(() => data, [data])

        const memoizedColumns = useMemo(
            () =>
                columnsData.map((column) => ({
                    ...column,
                    headerClass: `${classes.customHeader} ${column.headerClass || ''}`
                })),
            [columnsData, classes.customHeader]
        )

        const defaultColDef = useMemo<ColDef>(() => {
            return {
                flex: 1,
                minWidth: 100,
                cellStyle: {
                    borderLeft: '1px solid #E6E6E6',
                    lineHeight: '1.7',
                    wordBreak: 'break-word',
                    whiteSpace: 'normal',
                    font: '400 16px/20px Source Sans Pro'
                },
                wrapText: true,
                autoHeight: true,
                suppressSizeToFit: true,
                resizable: true
            }
        }, [])

        const noRowsOverlayComponent = useMemo(() => {
            return OverlayNoRowsTemplate
        }, [OverlayNoRowsTemplate])
        if (!memoizedData || memoizedData.length === 0)
            return <OverlayNoRowsTemplate formData={formData} />

        return (
            <div className={`${classes.root}`}>
                <div className={`ag-theme-alpine ${classes.root} ${gridClass}`} ref={containerRef}>
                    <AgGridReact
                        columnDefs={memoizedColumns}
                        rowData={memoizedData}
                        domLayout="autoHeight"
                        pagination={pagination}
                        gridOptions={gridOptions}
                        paginationPageSize={10}
                        rowSelection="multiple"
                        suppressRowClickSelection={true}
                        rowMultiSelectWithClick={true}
                        animateRows={true}
                        defaultColDef={defaultColDef}
                        suppressDragLeaveHidesColumns={true}
                        onGridReady={onGridReady}
                        onCellValueChanged={onCellValueChanged}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        onSortChanged={onSortChanged}
                        onCellClicked={gridOptions?.onCellClicked}
                        onCellKeyPress={gridOptions?.onCellClicked}
                        tooltipShowDelay={0}
                        onColumnResized={onColumnResized}
                    />
                </div>
            </div>
        )
    }
)

export default AgGridComponent
