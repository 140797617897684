import {
    communicationState,
    dataState,
    controlState,
    debounce
} from '../components/helpers/reducerHelpers'
import dataManager from '../businesslayer/minutesDataManager'
import { transientStorageManager } from '../businesslayer/minutesSessionStore'
import { getSessionStorageItem } from 'businesslayer/minutesLocalStore'

import { createAction, handleActions } from 'redux-actions'
import { exportActions } from 'businesslayer/api/actions'
import { getMinutesDetail, exportMinutes } from 'businesslayer/api/minutes'
import {
    deleteSection,
    saveSection,
    updateSectionOrder,
    updateSectionContent,
    updateSectionName
} from 'businesslayer/api/sections'
import { EDITOR_TABS } from 'components/minutetaker/components/InvitationView/types'

//So we debounce savings PER SECTION
//Ohherwise if user types and the suddenly moves to another section a saving for
//previous section will be canceled and it is data loss
let saveDebouncers = new Map()

const debouncedSave = (section, resolve, reject) => {
    const id = section.id || section.tempId
    if (saveDebouncers.has(id)) {
        return saveDebouncers.get(id)(section, resolve, reject)
    } else {
        const debounceTimeout = 2000
        const debouncer = debounce((obj, resolve, reject) => {
            saveSection(obj).then(resolve).catch(reject)
        }, debounceTimeout)

        saveDebouncers.set(id, debouncer)
        debouncer(section, resolve, reject)
    }
}
interface MinutesAction {
    type: string
    payload?: any
}

interface UpdateReviewersAction extends MinutesAction {
    type: 'REVIEWERS'
}

export const actions = {
    fetchMinuteItem: createAction('FETCH_MINUTE_ITEM', (minutesId) => {
        return getMinutesDetail(minutesId, actions.fetchMinuteItem.bind(null, minutesId))
    }),
    fetchMinuteItemPending: createAction('FETCH_MINUTE_ITEM_PENDING'),
    fetchMinuteItemFulfilled: createAction('FETCH_MINUTE_ITEM_FULFILLED'),
    fetchMinuteItemRejected: createAction('FETCH_MINUTE_ITEM_REJECTED'),
    toggleExpandCollapseAll: createAction('TOGGLE_EXPAND_COLLAPSE_ALL', (expanded) => expanded),

    toggleSectionExpandCollapse: createAction('TOGGLE_SECTION_EXPAND_COLLAPSE'),
    ensureSectionExpanded: createAction('ENSURE_SECTION_EXPANDED'),
    loadSections: createAction('LOAD_SECTIONS'),

    addSection: createAction('ADD_SECTION'),

    saveSection: createAction('SAVE_SECTION', (section) => {
        return new Promise((resolve, reject) => debouncedSave(section, resolve, reject))
    }),
    saveSectionPending: createAction('SAVE_SECTION_PENDING'),
    saveSectionFulfilled: createAction('SAVE_SECTION_FULFILLED'),
    saveSectionRejected: createAction('SAVE_SECTION_REJECTED'),

    updateSectionContent: createAction(
        'UPDATE_SECTION_CONTENT',
        ({ id, minutesId, html, reviewContent }) => {
            return updateSectionContent({ sectionId: id, minutesId, content: html, reviewContent })
        }
    ),
    updateSectionContentPending: createAction('UPDATE_SECTION_CONTENT_PENDING'),
    updateSectionContentFulfilled: createAction('UPDATE_SECTION_CONTENT_FULFILLED'),
    updateSectionContentRejected: createAction('UPDATE_SECTION_CONTENT_REJECTED'),

    updateSectionName: createAction('UPDATE_SECTION_NAME', ({ id, minutesId, name }) => {
        return updateSectionName({ sectionId: id, minutesId, name })
    }),
    updateSectionNamePending: createAction('UPDATE_SECTION_NAME_PENDING'),
    updateSectionNameFulfilled: createAction('UPDATE_SECTION_NAME_FULFILLED'),
    updateSectionNameRejected: createAction('UPDATE_SECTION_NAME_REJECTED'),

    deleteSection: createAction('DELETE_SECTION', (sectionId, minutesId) => {
        return deleteSection(
            sectionId,
            minutesId,
            actions.deleteSection.bind(null, sectionId, minutesId)
        )
    }),
    deleteSectionPending: createAction('DELETE_SECTION_PENDING'),
    deleteSectionFulfilled: createAction('DELETE_SECTION_FULFILLED'),
    deleteSectionRejected: createAction('DELETE_SECTION_REJECTED'),
    deactivateSection: createAction('DEACTIVATE_SECTION'),

    purgeSection: createAction('PURGE_SECTION'),

    parseSectionData: createAction('PARSE_SECTION_DATE'),
    syncSectionLocalDataChanges: createAction('SYNC_SECTION_LOCAL_DATA_CHANGES'),
    syncAttendeesLocalDataChanges: createAction('SYNC_ATTENDEES_LOCAL_DATA_CHANGES'),

    resetCurrentMinuteItem: createAction('RESET_CURRENT_MINUTE_ITEM'),
    updateCurrentMinutesItem: createAction('UPDATE_CURRENT_MINUTES_ITEM'),

    changeSectionOrder: createAction('CHANGE_SECTION_ORDER', (fromOrder, toOrder) => {
        return { fromOrder, toOrder }
    }),
    changeSectionOrderComplete: createAction('CHANGE_SECTION_ORDER_COMPLETE'),
    addAction: createAction('ADD_TAKER_ACTION'),

    resetEditedAction: createAction('RESET_EDITED_ACTION'),

    setSelectedSection: createAction('SET_SELECTED_SECTION'),

    loadActionsPending: createAction('LOAD_ACTIONS_PENDING'),
    loadActionsFulfilled: createAction('LOAD_ACTIONS_FULFILLED'),
    loadActionsRejected: createAction('LOAD_ACTIONS_REJECTED'),

    appendTakerListAction: createAction('APPEND_TAKER_LIST_ACTION'),
    updateTakerListAction: createAction('UPDATE_TAKER_LIST_ACTION'),

    editAction: createAction('EDIT_TAKER_ACTION'),
    removeAction: createAction('DELETE_TAKER_ACTION'),
    deleteActionsBySection: createAction('DELETE_ACTIONS_BY_SECTION'),

    exportMinutes: createAction('EXPORT_MINUTES', (minutesId, minutesOnly = false) => {
        const committeeId = getSessionStorageItem('currentCommitteeId')
        return exportMinutes(
            committeeId,
            minutesId,
            minutesOnly,
            actions.exportMinutes.bind(null, committeeId, minutesId, minutesOnly)
        )
    }),
    exportMinutesPending: createAction('EXPORT_MINUTES_PENDING'),
    exportMinutesFulfilled: createAction('EXPORT_MINUTES_FULFILLED'),
    exportMinutesRejected: createAction('EXPORT_MINUTES_REJECTED'),

    exportActions: createAction('EXPORT_ACTIONS', (minutesId, sortField) => {
        const committeeId = getSessionStorageItem('currentCommitteeId')
        return exportActions(
            committeeId,
            minutesId,
            sortField,
            actions.exportMinutes.bind(null, committeeId, minutesId, sortField)
        )
    }),
    exportActionsPending: createAction('EXPORT_ACTIONS_PENDING'),
    exportActionsFulfilled: createAction('EXPORT_ACTIONS_FULFILLED'),
    exportActionsRejected: createAction('EXPORT_ACTIONS_REJECTED'),

    setCollapseAllVisibility: createAction('SET_COLLAPSE_ALL_VISIBILITY'),
    selectTab: createAction('SELECT_TAB'),
    selectActionItem: createAction('SELECT_ACTION_ITEM'),
    handleActionStatusChanged: createAction('HANDLE_ACTION_STATUS_CHANGED'),

    textFormattingTriggered: createAction('TEXT_FORMATTING_TRIGGERED'),
    textFormattingProcessed: createAction('TEXT_FORMATTING_PROCESSED'),
    textFormattingRequestUpdatePanelState: createAction(
        'TEXT_FORMATTING_REQUEST_UPDATE_PANEL_STATE'
    ),
    indentBlock: createAction('INDENT_BLOCK'),
    outdentBlock: createAction('OUTDENT_BLOCK'),
    rightAlignBlock: createAction('RIGHT_ALIGN_BLOCK'),
    leftAlignBlock: createAction('LEFT_ALIGN_BLOCK'),
    centerAlignBlock: createAction('CENTER_ALIGN_BLOCK'),
    justifyAlignBlock: createAction('JUSTIFY_ALIGN_BLOCK'),
    clearBlockFormattingAction: createAction('CLEAR_BLOCK_FORMATTING_ACTION'),
    updateReviewers: createAction<UpdateReviewersAction>('REVIEWERS'),
    currentSelectedTab: createAction('CURRENT_SELECTED_TAB'),
    showSideBar: createAction('SHOW_SIDEBAR')
}

//http://jamesknelson.com/5-types-react-application-state/
export interface TakerControlState {
    isMinutesExpanded: boolean
    isEditingAction: boolean
    sectionOpenStates: Map<string, boolean>
    selectedSection: string | null
    collapseAllVisible: boolean
    selectedTabIndex: number
    selectedActionItem: any
    blockFormattingAction: any
    textFormattingActionPending: any
    textFormattingRequestUpdatePanelState: any
    reviewerData: any
    reviewers: {
        recipients: PersonChip[]
        notification_send: boolean
        subject: string
        body: string
        edited_reviews: number[]
        cancelled_reviews: number[]
        new_invitees: number[]
        parent_platform: string
    }
    currentSelectedTab: MinutesToolbarTab
    showSideBar: boolean
}

export type MinutesToolbarTab = EDITOR_TABS.EDITOR | EDITOR_TABS.ACTIONS | EDITOR_TABS.ACTIONS
export interface DataState {
    currentMinuteItem: any
    minutesSections: any
    committeeId: any
    minutesActions: any
    editedAction: any
    lastExport: any
    lastExportType: any
    dateFormat: any
    timeFormat: any
    sectionLastUpdatedAt: any
}

interface CommunicationState {
    isLoadingMinuteItem: boolean
    isSavingSection: boolean
    deletingSectionId: string | null
    isLoadingActions: boolean
    isExportingMinutes: boolean
}
interface SessionState {
    minutesId?: string
    id?: string
    name?: string
    order?: number
    level?: string
    body?: any
    html?: any
    sectionType?: string
    updatedAt?: string
    loaded?: boolean
}

export interface MinutesTakerState {
    controlState: TakerControlState
    dataState: DataState
    communicationState: CommunicationState
    sessionState?: SessionState
}
const initialState: MinutesTakerState = {
    controlState: {
        isMinutesExpanded: true,
        isEditingAction: false,
        sectionOpenStates: new Map(),
        selectedSection: null,
        collapseAllVisible: true,
        selectedTabIndex: 0,
        selectedActionItem: null,
        blockFormattingAction: undefined,
        textFormattingActionPending: null,
        textFormattingRequestUpdatePanelState: null,
        reviewerData: null,
        reviewers: {
            recipients: [],
            notification_send: false,
            subject: '',
            body: '',
            edited_reviews: [],
            cancelled_reviews: [],
            new_invitees: [],
            parent_platform: ''
        },
        currentSelectedTab: EDITOR_TABS.EDITOR,
        showSideBar: false
    },
    dataState: {
        currentMinuteItem: null,
        minutesSections: null,
        committeeId: null,
        minutesActions: null,
        editedAction: null,
        lastExport: null,
        lastExportType: null,
        dateFormat: null,
        timeFormat: null,
        sectionLastUpdatedAt: null
    },
    communicationState: {
        isLoadingMinuteItem: false,
        isSavingSection: false,
        deletingSectionId: null,
        isLoadingActions: false,
        isExportingMinutes: false
    },
    sessionState: {}
}

let updatedState = (null as unknown) as MinutesTakerState

const reducer = handleActions(
    {
        [actions.indentBlock](state: MinutesTakerState) {
            return controlState(state, {
                blockFormattingAction: 'indent'
            })
        },

        [actions.outdentBlock](state: MinutesTakerState) {
            return controlState(state, {
                blockFormattingAction: 'outdent'
            })
        },

        [actions.rightAlignBlock](state: MinutesTakerState) {
            return controlState(state, {
                blockFormattingAction: 'rightAlign'
            })
        },

        [actions.centerAlignBlock](state: MinutesTakerState) {
            return controlState(state, {
                blockFormattingAction: 'centerAlign'
            })
        },

        [actions.leftAlignBlock](state: MinutesTakerState) {
            return controlState(state, {
                blockFormattingAction: 'leftAlign'
            })
        },

        [actions.justifyAlignBlock](state: MinutesTakerState) {
            return controlState(state, {
                blockFormattingAction: 'justifyAlign'
            })
        },

        [actions.clearBlockFormattingAction](state: MinutesTakerState) {
            return controlState(state, {
                blockFormattingAction: undefined
            })
        },

        [actions.resetCurrentMinuteItem]: (state: MinutesTakerState) => {
            return dataState(state, {
                currentMinuteItem: null,
                minutesSections: null,
                selectedSection: null,
                minutesActions: null,
                lastExport: null
            })
        },

        [actions.fetchMinuteItemPending]: (state: MinutesTakerState) => {
            updatedState = communicationState(state, { isLoadingMinuteItem: true })
            updatedState = dataState(updatedState, {
                currentMinuteItem: null,
                minutesSections: null,
                selectedSection: null,
                minutesActions: null
            })

            updatedState = controlState(updatedState, {
                sectionOpenStates: new Map(),
                isMinutesExpanded: true
            })

            return updatedState
        },

        [actions.updateCurrentMinutesItem]: (_state, action) => {
            updatedState = dataState(updatedState, { currentMinuteItem: action.payload })

            return updatedState
        },

        [actions.fetchMinuteItemFulfilled]: (state, action) => {
            updatedState = state

            if (action.payload.minutesDocuments) {
                //This is item came from server as response

                //TODO: transfer committeee once coming here from manager
                if (action.payload.committees) {
                    const keys = Object.keys(action.payload.committees)
                    updatedState = dataState(updatedState, { committeeId: keys ? keys[0] : null })
                }

                const keys = Object.keys(action.payload.minutesDocuments)
                if (keys) {
                    updatedState = dataState(updatedState, {
                        currentMinuteItem: action.payload.minutesDocuments[keys[0]]
                    })
                }
            } else {
                //This is item came from transient storage as result of user selecting it in the grid
                updatedState = dataState(updatedState, { currentMinuteItem: action.payload })
            }

            updatedState = dataState(updatedState, {
                dateFormat: transientStorageManager.dateFormat,
                timeFormat: transientStorageManager.timeFormat
            })
            updatedState = communicationState(updatedState, { isLoadingMinuteItem: false })

            return updatedState
        },

        [actions.fetchMinuteItemRejected]: (state: MinutesTakerState) => {
            return communicationState(state, { isLoadingMinuteItem: false })
        },

        [actions.toggleExpandCollapseAll]: (state, action) => {
            let updatedState = controlState(state, {
                isMinutesExpanded: action.payload
            }) as MinutesTakerState

            const allStates = updatedState.controlState.sectionOpenStates
            const minutesSections = updatedState.dataState.minutesSections

            if (minutesSections) {
                minutesSections.forEach((c) => {
                    const id = c.id || c.tempId
                    allStates.set(
                        id,
                        c.sectionType === 'attendees'
                            ? false
                            : updatedState.controlState.isMinutesExpanded
                    )
                })
            }

            return controlState(updatedState, { sectionOpenStates: new Map(allStates) })
        },

        [actions.toggleSectionExpandCollapse]: (state, action) => {
            const allStates = state.controlState.sectionOpenStates
            const minutesSections = updatedState.dataState.minutesSections
            if (minutesSections && allStates.size === 0) {
                minutesSections.forEach((c) => {
                    const id = c.id || c.tempId
                    allStates.set(id, c.sectionType === 'attendees' ? false : true)
                })
            }
            if (allStates.has(action.payload)) {
                const curSection = !allStates.get(action.payload)
                allStates.set(action.payload, curSection)
            } else {
                allStates.set(action.payload, false) //Default state is open so we create closed upon click
            }
            const isMinutesExpanded = Array.from(allStates.values()).filter((x) => x).length > 0

            return controlState(state, { sectionOpenStates: new Map(allStates), isMinutesExpanded })
        },

        [actions.ensureSectionExpanded]: (state, action) => {
            if (!action.payload) {
                return state
            }
            const allStates = state.controlState.sectionOpenStates
            const key = `${action.payload}`
            if (allStates.has(key)) {
                allStates.set(key, true)
            }
            return controlState(state, { sectionOpenStates: new Map(allStates) })
        },

        [actions.loadSections]: (state, action) => {
            let sections = action.payload.minutesSections

            let sort = action.payload.sort
            if (sort && sort.length) {
                sections = sort.map((c) => sections[c])
            }

            if (!state.dataState.currentMinuteItem) {
                //This may happen when user naviogates back to manager quickly
                return state
            }

            const minutesId = state.dataState.currentMinuteItem.id

            if (sections.length === 1) {
                //Attendees section is always created by server so we push it first
                const attendeesAttrs = sections[0].attributes

                let attendeesSection = dataManager.createDefaultSection(minutesId, 'attendees')
                //Check just in case
                if (attendeesAttrs.sectionType === 'attendees') {
                    const truncated = attendeesAttrs.jsonBody
                    //TODO: currently json body is plain text!
                    attendeesSection = {
                        minutesId: minutesId,
                        id: sections[0].id,
                        name: attendeesAttrs.name,
                        order:
                            attendeesAttrs.order !== null ? parseInt(attendeesAttrs.order, 10) : 0,
                        level: attendeesAttrs.level,
                        body: truncated,
                        html: attendeesAttrs.htmlBody,
                        sectionType: attendeesAttrs.sectionType,
                        updatedAt: attendeesAttrs.updatedAt,
                        loaded: attendeesAttrs.sectionType === 'attendees' //TODO: that might need a change
                    } as any
                }

                const defaultSection = dataManager.createDefaultSection(minutesId, 'minutes')
                updatedState = dataState(state, {
                    minutesSections: [attendeesSection, defaultSection]
                })
            } else {
                let result: SessionState[] = []

                sections.forEach((section) => {
                    const attrs = section.attributes

                    const truncated = attrs.jsonBody
                    //Note: as soon as we get items from server we mark each item loaded = false
                    //TODO: currently json body for attendees is plain text!
                    const newData = {
                        minutesId: minutesId,
                        id: section.id,
                        name: attrs.name,
                        order: attrs.order !== null ? parseInt(attrs.order, 10) : 1,
                        level: attrs.level,
                        body: attrs.sectionType === 'attendees' ? truncated : JSON.parse(truncated),
                        html: attrs.htmlBody,
                        sectionType: attrs.sectionType,
                        updatedAt: attrs.updatedAt,
                        loaded: attrs.sectionType === 'attendees'
                    } as SessionState
                    result.push(newData)
                })
                updatedState = dataState(state, { minutesSections: result })
            }

            return updatedState
        },

        [actions.addSection]: (state: MinutesTakerState) => {
            const minutesSections = state.dataState.minutesSections

            if (!minutesSections) {
                return state
            }

            const maxOrder = minutesSections.reduce((prev, cur) => {
                return Math.max(prev, cur.order)
            }, 1)

            const minutesId = state.dataState.currentMinuteItem.id

            const newSection = dataManager.createDefaultSection(minutesId)
            newSection.order = isNaN(maxOrder) ? 1 : maxOrder + 1
            //As this is new section no need to "load" it
            newSection.loaded = true
            minutesSections.push(newSection)

            const sections = [...minutesSections]
            state.controlState.isMinutesExpanded = true
            return {
                ...state,
                controlState: {
                    ...state.controlState,
                    isMinutesExpanded: true
                },
                dataState: {
                    ...state.dataState,
                    minutesSections: sections
                }
            }
        },

        [actions.saveSectionFulfilled]: (state, action) => {
            let updatedState = state
            if (!action.payload.minutesSections) {
                return updatedState
            }

            //Step 1: Update transitory storage if any
            let minutesSections = updatedState.dataState.minutesSections
            const updatedMinutesSections = action.payload.minutesSections

            if (!state.dataState.currentMinuteItem) {
                return updatedState
            }
            const minutesId = state.dataState.currentMinuteItem.id
            const keys = Object.keys(updatedMinutesSections)

            if (!keys.length) {
                //TODO: Report rubbish from server
                return updatedState
            }
            const updatedId = keys[0]
            const updatedSection = updatedMinutesSections[updatedId]

            const existingSaved = minutesSections.find((c) => c.id === updatedId)

            if (!existingSaved) {
                //Looks like new object was just updated, we need to find first one having temp id but not having id
                const existingNotSaved = minutesSections.find(
                    (c) =>
                        c.tempId && !c.id && c.sectionType === updatedSection.attributes.sectionType
                )

                if (existingNotSaved) {
                    //console.log(`Updated NEW section ${JSON.stringify(existingNotSaved)}`)

                    const indexToRemove = minutesSections.indexOf(existingNotSaved)

                    dataManager.updateSectionObject(existingNotSaved, updatedSection, minutesId)
                    const refreshedObject = Object.assign({}, existingNotSaved)

                    if (indexToRemove > -1) {
                        minutesSections = [
                            ...minutesSections.slice(0, indexToRemove),
                            refreshedObject,
                            ...minutesSections.slice(indexToRemove + 1)
                        ]
                    }
                } else {
                    //TODO : Report exception
                    console.log('Could not find newly created section to update after first save!')
                }
            } else {
                //console.log(`Updated EXISTING section ${JSON.stringify(existingSaved)}`)

                dataManager.updateSectionObject(existingSaved, updatedSection, minutesId)
                const sectionToReplace = minutesSections.find((c) => c.id === updatedId)

                if (sectionToReplace) {
                    const indexToReplace = minutesSections.indexOf(sectionToReplace)
                    minutesSections[indexToReplace] = Object.assign({}, sectionToReplace)
                }
            }

            updatedState = communicationState(updatedState, { isSavingSection: false })
            return dataState(updatedState, { minutesSections: [...minutesSections] })
        },

        [actions.saveSectionPending]: (state: MinutesTakerState) => {
            return communicationState(state, { isSavingSection: true })
        },

        [actions.saveSectionRejected]: (state: MinutesTakerState) => {
            return communicationState(state, { isSavingSection: false })
        },

        [actions.updateSectionContentPending]: (state: MinutesTakerState) => {
            return communicationState(state, { isSavingSection: true })
        },

        [actions.updateSectionContentRejected]: (state: MinutesTakerState) => {
            return communicationState(state, { isSavingSection: false })
        },

        [actions.updateSectionContentFulfilled]: (state, action) => {
            if (!action.payload.minutesSections) {
                return updatedState
            }
            if (!state.dataState.currentMinuteItem) {
                return updatedState
            }

            const { minutesSections: minutesSectionsResponse } = action.payload
            const keys = Object.keys(minutesSectionsResponse)

            if (!keys.length) {
                //TODO: Report rubbish from server
                return updatedState
            }

            const sectionFromServer = mapUpdatedSectionFromResponse(action.payload)
            const existingSection = state.dataState.minutesSections.find(
                (section) => section.id === sectionFromServer.id
            )
            const updatedSection = existingSection
                ? {
                      ...existingSection,
                      htmlBody: sectionFromServer.htmlBody,
                      html: sectionFromServer.htmlBody,
                      updatedAt: sectionFromServer.updatedAt
                  }
                : existingSection

            const sectionLastUpdatedAt = sectionFromServer.updatedAt

            const updatedSections = state.dataState.minutesSections.map((section) =>
                section.id === sectionFromServer.id ? updatedSection : section
            )

            updatedState = communicationState(state, { isSavingSection: false })
            return dataState(updatedState, {
                minutesSections: updatedSections,
                sectionLastUpdatedAt
            })
        },

        [actions.updateSectionNamePending]: (state: MinutesTakerState) => {
            return communicationState(state, { isSavingSection: true })
        },

        [actions.updateSectionNameRejected]: (state: MinutesTakerState) => {
            return communicationState(state, { isSavingSection: false })
        },

        [actions.updateSectionNameFulfilled]: (state, action) => {
            if (!action.payload.minutesSections) {
                return updatedState
            }
            if (!state.dataState.currentMinuteItem) {
                return updatedState
            }

            const { minutesSections: minutesSectionsResponse } = action.payload
            const keys = Object.keys(minutesSectionsResponse)

            if (!keys.length) {
                //TODO: Report rubbish from server
                return updatedState
            }

            const sectionFromServer = mapUpdatedSectionFromResponse(action.payload)
            const existingSection = state.dataState.minutesSections.find(
                (section) => section.id === sectionFromServer.id
            )

            const updatedSection = existingSection
                ? {
                      ...existingSection,
                      htmlBody: sectionFromServer.htmlBody,
                      html: sectionFromServer.htmlBody,
                      updatedAt: sectionFromServer.updatedAt
                  }
                : existingSection

            const sectionLastUpdatedAt = sectionFromServer.updatedAt

            const updatedSections = state.dataState.minutesSections.map((section) =>
                section.id === sectionFromServer.id ? updatedSection : section
            )

            updatedState = communicationState(state, { isSavingSection: false })
            return dataState(updatedState, {
                minutesSections: updatedSections,
                sectionLastUpdatedAt
            })
        },

        [actions.deactivateSection]: (state, action) => {
            return communicationState(state, { deletingSectionId: action.payload })
        },

        [actions.deleteSectionFulfilled]: (state, action) => {
            let updatedState = communicationState(state, { deletingSectionId: null })

            const sectionId = action.payload
            if (!sectionId) {
                return updatedState
            }

            if (
                state.controlState.selectedSection &&
                state.controlState.selectedSection.id === sectionId
            ) {
                //Looks like user deleted selected section so we need to reset it
                updatedState = controlState(updatedState, { selectedSection: null })
            }

            const minutesSections = updatedState.dataState.minutesSections
            const sectionToRemove = minutesSections.find((c) => c.id === sectionId)

            const itemIndex = minutesSections.indexOf(sectionToRemove)
            if (itemIndex < 0) {
                return updatedState
            }
            const newSections = [
                ...minutesSections.slice(0, itemIndex),
                ...minutesSections.slice(itemIndex + 1)
            ]
            //We dont get any data back on section delete.
            //But we still would want to have some indication of update time in parent minutes item
            //
            const currentMinuteItem = state.dataState.currentMinuteItem
            currentMinuteItem.attributes.updatedAt = new Date().toISOString()
            const sectionOpenStates = state.controlState.sectionOpenStates
            sectionOpenStates.delete(sectionToRemove.id.toString())
            const isMinutesExpanded =
                Array.from(sectionOpenStates.values()).filter((x) => x).length > 0
            return {
                ...state,
                dataState: {
                    ...state.dataState,
                    updatedState,
                    minutesSections: newSections,
                    currentMinuteItem: currentMinuteItem
                },
                controlState: {
                    ...state.controlState,
                    isMinutesExpanded: isMinutesExpanded
                }
            }
        },

        [actions.purgeSection]: (state, action) => {
            let updatedState = state

            const sectionId = action.payload
            if (!sectionId) {
                return updatedState
            }

            const minutesSections = updatedState.dataState.minutesSections
            //Note: we use tempId here
            const sectionToRemove = minutesSections.find((c) => c.tempId === sectionId)

            const itemIndex = minutesSections.indexOf(sectionToRemove)

            if (itemIndex < 0) {
                return updatedState
            }
            const newSections = [
                ...minutesSections.slice(0, itemIndex),
                ...minutesSections.slice(itemIndex + 1)
            ]
            return dataState(updatedState, { minutesSections: newSections })
        },

        [actions.parseSectionData]: (state, action) => {
            //We load items one by one in a serial way
            const minutesSections = updatedState.dataState.minutesSections

            if (minutesSections) {
                const section = action.payload
                const index = minutesSections.indexOf(section)

                if (index < 0) {
                    return state
                }
                let newSection = Object.assign({}, section)
                newSection.loaded = true

                minutesSections[index] = newSection

                return dataState(state, { minutesSections: [...minutesSections] })
            }
            return state
        },

        //We have to make sure all UIs get data updates even before save is bounced

        [actions.syncSectionLocalDataChanges]: (state, action) => {
            const minutesSections = state.dataState.minutesSections

            if (minutesSections) {
                const section = action.payload
                const keyToFind = section.tempId || section.id
                const index = minutesSections.findIndex((c) => (c.tempId || c.id) === keyToFind)

                if (index < 0) {
                    return state
                }
                //NOTE: Original implementation worked perfect but cuased huge slowness
                //We wont be changing sections each update. Just change content

                //Original implementation : SUPER SLOW, BUT 100% working
                /*let newSection = Object.assign({}, section)
            minutesSections[index] = newSection

            return dataState(state, { minutesSections : [...minutesSections]})*/

                //New implementation: we call this.forceUpdate in each component
                minutesSections[index].name = section.name
                //minutesSections[index].body = section.body
            }
            return state
        },

        [actions.syncAttendeesLocalDataChanges]: (state, action) => {
            if (!action.payload) {
                return state
            }
            const item = Object.assign({}, updatedState.dataState.currentMinuteItem)

            //NOTE: we simply assign array without transformation back to server format
            //becuase all we need is count
            item.attributes.attendees = action.payload

            return dataState(state, { currentMinuteItem: item })
        },

        [actions.changeSectionOrder]: (state, action) => {
            if (!action.payload) {
                return state
            }
            const sections = state.dataState.minutesSections

            const fromIndex = action.payload.fromOrder
            const toIndex = action.payload.toOrder
            const fromItem = sections[fromIndex]
            let newSections = [...sections.slice(0, fromIndex), ...sections.slice(fromIndex + 1)]
            newSections.splice(toIndex, 0, fromItem)
            let index = 0
            newSections.forEach((c) => (c.order = index++))
            updateSectionOrder(newSections, actions.changeSectionOrderComplete)
            updatedState = communicationState(state, { isSavingSection: true })
            return dataState(updatedState, { minutesSections: [...newSections] })
        },

        [actions.changeSectionOrderComplete]: (state: MinutesTakerState) => {
            //We dont get any data back on section order saved.
            //But we still would want to have some indication of update time in parent minutes item
            //
            const currentMinuteItem = state.dataState.currentMinuteItem
            currentMinuteItem.attributes.updatedAt = new Date().toISOString()

            updatedState = dataState(state, { currentMinuteItem: currentMinuteItem })

            return communicationState(updatedState, { isSavingSection: false })
        },

        [actions.editAction]: (state, { payload: { action, block } }) => {
            const actionId = `${action.actionId}`

            if (!actionId) {
                updatedState = dataState(state, { editedAction: null, editedBlock: null })
                return controlState(updatedState, { isEditingAction: false })
            }

            const editedAction = state.dataState.minutesActions
                ? state.dataState.minutesActions.find((c) => c.id === actionId)
                : null

            if (!editedAction) {
                updatedState = dataState(state, { editedAction: null, editedBlock: null })
                return controlState(updatedState, { isEditingAction: false })
            }

            let cloned = Object.assign({}, editedAction)

            if (editedAction.assignees) {
                cloned.assignees = [...editedAction.assignees]
            }

            updatedState = dataState(state, { editedAction: cloned, editedBlock: block })
            return controlState(updatedState, { isEditingAction: true })
        },

        [actions.deleteActionsBySection]: (state, { payload: sectionId }) => {
            if (!sectionId || !state.dataState.minutesActions) return state

            const minutesActions = state.dataState.minutesActions.filter(
                (row) => +row.minutesSectionId !== +sectionId
            )

            return dataState(state, { minutesActions })
        },

        [actions.removeAction]: (state, action) => {
            const actionId = `${action.payload}`

            if (!actionId) {
                return state
            }

            let minutesActions = state.dataState.minutesActions

            if (!minutesActions) {
                return state
            }

            minutesActions = minutesActions.filter((row) => row.id !== actionId)

            return dataState(state, { minutesActions })
        },

        [actions.addAction]: (state: MinutesTakerState) => {
            updatedState = dataState(state, { editedAction: null, editedBlock: null })
            return controlState(updatedState, { isEditingAction: true })
        },

        [actions.resetEditedAction]: (state: MinutesTakerState) => {
            updatedState = dataState(state, { editedAction: null, editedBlock: null })
            return controlState(updatedState, { isEditingAction: false })
        },

        [actions.setSelectedSection]: (state, action) => {
            return controlState(state, { selectedSection: action.payload })
        },

        [actions.loadActionsPending]: (state: MinutesTakerState) => {
            return communicationState(state, { isLoadingActions: true })
        },

        [actions.loadActionsFulfilled]: (state, action) => {
            const actionItems = [
                ...action.payload.actions.data,
                ...action.payload.general_actions.data
            ]

            if (!actionItems) {
                return dataState(state, { minutesActions: null })
            }

            const keys = Object.keys(actionItems)

            let minutesActions: any = []
            keys.forEach((id) => {
                const minutesAction = actionItems[id]

                let assignees = minutesAction.attributes.assignees

                if (assignees) {
                    assignees = assignees.map((c) => {
                        return {
                            email: c.email,
                            id: c.id,
                            text: c.display_name,
                            self_manage: c.self_manage
                        }
                    })
                }
                minutesActions.push({
                    id: minutesAction.id,
                    sortOrder: minutesAction.attributes.sort_order,
                    dueDate: minutesAction.attributes.due_date,
                    minutesId: minutesAction.attributes.minutes_document_id,
                    minutesSectionId: minutesAction.attributes.minutes_section_id,
                    text: minutesAction.attributes.text
                        .replace(/^(["]*)/g, '')
                        .replace(/(["]*)$/g, ''),
                    notificationStatus: minutesAction.attributes.notification_status || 'unsent',
                    completionStatus: minutesAction.attributes.completion_status || 'incomplete',
                    notes: minutesAction.attributes.note,
                    assignees: assignees,
                    createdAt: minutesAction.attributes.created_at,
                    updatedAt: minutesAction.attributes.updated_at,
                    completed_date: minutesAction.attributes.completed_date,
                    status: minutesAction.attributes.status
                })
            })

            updatedState = communicationState(state, { isLoadingActions: false })

            return dataState(updatedState, { minutesActions: minutesActions })
        },

        [actions.handleActionStatusChanged]: (state, action) => {
            const currentActions = state.dataState.minutesActions
            const updatedAction = action.payload

            if (!currentActions || !updatedAction) {
                return state
            }
            const indexToReplace = currentActions.findIndex((c) => c.id === updatedAction.id)

            if (indexToReplace > -1) {
                let assignees = updatedAction.attributes.assignees

                if (assignees) {
                    assignees = assignees.map((c) => {
                        return {
                            email: c.email,
                            id: c.id,
                            text: c.display_name,
                            self_manage: c.self_manage
                        }
                    })
                }

                currentActions[indexToReplace] = {
                    id: updatedAction.id,
                    dueDate: updatedAction.attributes.dueDate,
                    minutesId: updatedAction.attributes.minutesDocumentId,
                    minutesSectionId: updatedAction.attributes.minutesSectionId,
                    text: updatedAction.attributes.text
                        .replace(/^(["]*)/g, '')
                        .replace(/(["]*)$/g, ''),
                    notificationStatus: updatedAction.attributes.notificationStatus || 'unsent',
                    completionStatus: updatedAction.attributes.completionStatus || 'incomplete',
                    assignees: assignees,
                    createdAt: updatedAction.attributes.createdAt,
                    updatedAt: updatedAction.attributes.updatedAt,
                    notes: updatedAction.attributes.note
                }

                return dataState(state, { minutesActions: [...currentActions] })
            }
            return state
        },

        [actions.loadActionsRejected]: (state: MinutesTakerState) => {
            updatedState = communicationState(state, { isLoadingActions: false })
            return dataState(updatedState, { minutesActions: null })
        },

        [actions.appendTakerListAction]: (state, action) => {
            const existingActions = state.dataState.minutesActions || []
            const minutesActions = [...existingActions, action.payload]
            return dataState(state, { minutesActions: minutesActions })
        },

        [actions.updateTakerListAction]: (state, action) => {
            const existingActions = state.dataState.minutesActions || []

            const index = existingActions.findIndex((c) => c.id === action.payload.id)

            if (index === -1) {
                return state
            }
            const minutesActions = [
                ...existingActions.slice(0, index),
                action.payload,
                ...existingActions.slice(index + 1)
            ]

            return dataState(state, { minutesActions: minutesActions })
        },

        // ================== Export
        [actions.exportMinutesPending]: (state: MinutesTakerState) => {
            updatedState = dataState(state, { lastExport: null, lastExportType: null })
            return communicationState(updatedState, { isExportingMinutes: true })
        },

        [actions.exportMinutesFulfilled]: (state, action) => {
            updatedState = dataState(state, { lastExport: action.payload, lastExportType: 'docx' })
            return communicationState(updatedState, { isExportingMinutes: false })
        },

        [actions.exportMinutesRejected]: (state: MinutesTakerState) => {
            updatedState = dataState(state, { lastExport: null, lastExportType: null })
            return communicationState(updatedState, { isExportingMinutes: false })
        },

        [actions.exportActionsPending]: (state: MinutesTakerState) => {
            updatedState = dataState(state, { lastExport: null, lastExportType: null })
            return communicationState(updatedState, { isExportingMinutes: true })
        },

        [actions.exportActionsFulfilled]: (state, action) => {
            updatedState = dataState(state, { lastExport: action.payload, lastExportType: 'xlsx' })
            return communicationState(updatedState, { isExportingMinutes: false })
        },

        [actions.exportActionsRejected]: (state: MinutesTakerState) => {
            updatedState = dataState(state, { lastExport: null, lastExportType: null })
            return communicationState(updatedState, { isExportingMinutes: false })
        },

        [actions.setCollapseAllVisibility]: (state, action) => {
            return controlState(state, { collapseAllVisible: action.payload })
        },

        [actions.selectTab]: (state, action) => {
            return controlState(state, { selectedTabIndex: action.payload })
        },

        [actions.selectActionItem]: (state, action) => {
            return controlState(state, { selectedActionItem: action.payload })
        },

        //================== Formatting

        [actions.textFormattingTriggered]: (state, action) => {
            //Note: payload is a object with all formatting settings
            return controlState(state, {
                textFormattingActionPending: action.payload
            })
        },

        [actions.textFormattingProcessed]: (state: MinutesTakerState) => {
            //Note: payload is a object with all formatting settings
            return controlState(state, {
                textFormattingActionPending: null
            })
        },

        [actions.textFormattingRequestUpdatePanelState]: (state, action) => {
            //Note: payload is a object with all formatting settings
            return controlState(state, {
                textFormattingRequestUpdatePanelState: action.payload
            })
        },
        [actions.updateReviewers]: (state, action) => {
            return controlState(state, {
                reviewers: action.payload
            })
        },
        [actions.currentSelectedTab]: (state, action) => {
            return controlState(state, {
                currentSelectedTab: action.payload
            })
        },
        [actions.showSideBar]: (state, action) => {
            return controlState(state, {
                showSideBar: action.payload
            })
        }
    },
    initialState
)

export default reducer

function mapUpdatedSectionFromResponse(response) {
    const { minutesSections } = response
    const keys = Object.keys(minutesSections)

    const id = keys[0]
    const { attributes } = minutesSections[id]

    return { id, ...attributes }
}
