import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { Form, Field, FormSpy } from 'react-final-form'
import { translate } from 'components/shared/internationalization'
import { SelectSectionField } from 'components/minutetaker/components/form/SelectSectionField'
import { AssigneeChipInputField } from 'components/minutetaker/components/form/AssigneeChipInputField'
import { DateField } from '../form/DateField'
import TextLengthCounter from 'components/shared/TextLengthCounter'
import { InputLabel, Checkbox, Typography, Tooltip } from '@material-ui/core'
import { NewDialogTextField } from 'components/shared/StyledComponents'
import { makeStyles } from '@material-ui/core/styles'
import { ColorBaseWhite } from 'assets/styles/variables'
import {
    getIntialValuesActions,
    getPreselectedSectionId,
    updateActionWithValues,
    validateActionValues
} from '../InvitationView/util'
import { CurrentMinutesProps, MinutesSectionsData } from 'Types'
import i18n from 'common/util/internationalization'

type Props = {
    action: any
    allContacts: Attendee[]
    dateInputMask: string
    sections: MinutesSectionsData[]
    selectedSection: any
    onActionItemSubmit: any
    setShowSaveButton: React.Dispatch<React.SetStateAction<boolean>>
    actions: any
    isAdmin: boolean
    currentMinuteItem: CurrentMinutesProps
}

const useStyles = makeStyles((theme) => ({
    textFieldContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        minHeight: 50,
        width: '100%',
        placeItems: 'center'
    },
    formControl: {
        width: '100%',
        marginTop: theme.spacing(1.3)
    },
    inputLabel: {
        marginTop: theme.spacing(1.3),
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '16px',
        textAlign: 'left',
        color: '#1E1E1E'
    },
    textField: {
        minHeight: 40,
        marginTop: theme.spacing(1),
        '& p': {
            marginLeft: 0,
            marginRight: 0
        }
    },
    dateField: {
        width: '100%',
        minWidth: '580px',
        paddingBottom: '10px',
        '& > div': {
            width: '100%'
        },
        '& > div > div': {
            width: '100%'
        },
        '& > *': {
            width: '100% !important'
        },
        [theme.breakpoints.down('md')]: {
            paddingTop: '12px',
            paddingBottom: '12px',
            '& > *': {
                width: '100% !important',
                display: 'contents'
            }
        }
    },
    sectionField: {
        paddingTop: '0px !important'
    },
    checkbox: {
        padding: '0px',
        '&.Mui-checked': {
            color: '#2F3B4D'
        },
        '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.26)'
        },
        marginRight: '8px',
        width: '24px',
        height: '24px',
        '&.Mui-focusVisible, &:active': {
            boxShadow: `0px 0px 0px 3px #0774EE, 0px 0px 0px 1px ${ColorBaseWhite}`,
            borderRadius: '4px'
        }
    },
    checkboxTitle: {
        fontFamily: 'Source Sans Pro',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '18px',
        letterSpacing: '0px',
        textAlign: 'left',
        padding: '0px',
        color: '#000000'
    },
    checkBoxContainer: {
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '16px',
        letterSpacing: '0px',
        textAlign: 'left',
        paddingBottom: '8px',
        placeItems: 'center',
        display: 'flex',
        paddingTop: '10px'
    },
    btnClass: {
        padding: '8px 12px 8px 12px'
    }
}))
const ActionEditFormRightPanel: React.FC<Props> = ({
    action = {},
    selectedSection,
    dateInputMask,
    sections,
    allContacts,
    onActionItemSubmit,
    isAdmin,
    setShowSaveButton
}) => {
    const classes = useStyles()
    const [maxLength, setMaxLength] = useState(0)
    const actionTextRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        setMaxLength(action?.action_title?.length)
        setTimeout(() => {
            if (actionTextRef.current) {
                actionTextRef.current.focus()
            }
        }, 5)
    }, [action])

    const handleSubmit = useCallback(
        ({ action, values }: { action: any; values: any }) => {
            updateActionWithValues(action, values)
            onActionItemSubmit(action)
        },
        [onActionItemSubmit]
    )

    const initialValues = useMemo(
        () => getIntialValuesActions(action, selectedSection, sections, dateInputMask),
        [action, selectedSection, sections, dateInputMask]
    )
    const isMemberSelfManage = !initialValues?.self_manage && !isAdmin
    const handleValidation = useCallback(
        (values: any) => {
            const fieldErrors = validateActionValues(values)
            setShowSaveButton(Object.keys(fieldErrors).length === 0 && maxLength <= 10000)
            return fieldErrors
        },
        [maxLength, setShowSaveButton]
    )
    const adminRestrictedChange = i18n.t('ADMIN_RESTRICTED_CHANGE')

    return (
        <Form
            initialValues={initialValues}
            onSubmit={(values) => handleSubmit({ values, action })}
            validate={(values) => handleValidation(values)}
            keepDirtyOnReinitialize={true}
            render={({ errors, handleSubmit, form, values }) => (
                <>
                    <h2>{translate('EDIT_ACTION_DETAILS')}</h2>
                    <form id="editActionsForm" onSubmit={handleSubmit}>
                        <FormSpy
                            subscription={{ submitFailed: true }}
                            onChange={({ submitFailed }) => {
                                form.getRegisteredFields().forEach((field) =>
                                    form.mutators.touched(field)
                                )
                                if (submitFailed) {
                                    form.getRegisteredFields().forEach((field) =>
                                        form.mutators.touched(field)
                                    )
                                }
                            }}
                        />
                        <div className={classes.textFieldContainer}>
                            <Field
                                name="action_completed"
                                disabled={isMemberSelfManage}
                                render={({ input: { onChange, checked, value, ...rest } }) => {
                                    return (
                                        <div className={classes.textFieldContainer}>
                                            <div style={{ width: '100%', paddingTop: '20px' }}>
                                                <Tooltip
                                                    title={
                                                        isMemberSelfManage
                                                            ? adminRestrictedChange
                                                            : ''
                                                    }
                                                    placement="top-start">
                                                    <div className={classes.checkBoxContainer}>
                                                        <Checkbox
                                                            disableFocusRipple
                                                            disabled={isMemberSelfManage}
                                                            disableRipple
                                                            className={classes.checkbox}
                                                            style={{
                                                                color: isMemberSelfManage
                                                                    ? 'rgba(0, 0, 0, 0.38)'
                                                                    : '#000000'
                                                            }}
                                                            checked={checked}
                                                            aria-checked="mixed"
                                                            role="checkbox"
                                                            color="default"
                                                            onChange={onChange}
                                                            {...rest}></Checkbox>
                                                        <Typography
                                                            className={classes.checkboxTitle}
                                                            style={{
                                                                cursor: isMemberSelfManage
                                                                    ? 'default'
                                                                    : 'pointer',
                                                                color: isMemberSelfManage
                                                                    ? 'rgba(0, 0, 0, 0.38)'
                                                                    : '#000000'
                                                            }}
                                                            onClick={() =>
                                                                !isMemberSelfManage &&
                                                                onChange(!checked)
                                                            }>
                                                            {translate('COMPLETE')}
                                                        </Typography>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    )
                                }}
                                type="checkbox"
                            />
                        </div>
                        <div className={classes.textFieldContainer}>
                            <Field
                                name="action_title"
                                render={({ input: { onChange, ...rest }, meta: { touched } }) => (
                                    <div className={classes.textFieldContainer}>
                                        <div style={{ width: '100%' }}>
                                            <InputLabel
                                                className={classes.inputLabel}
                                                htmlFor="action_title"
                                                required
                                                aria-required="true">
                                                {translate('ACTION')}
                                            </InputLabel>
                                            <div>
                                                <NewDialogTextField
                                                    className={classes.textField}
                                                    multiline={true}
                                                    variant="outlined"
                                                    required
                                                    disabled={!isAdmin}
                                                    aria-required="true"
                                                    error={
                                                        !!touched &&
                                                        !!errors &&
                                                        !!errors.action_title
                                                    }
                                                    helperText={
                                                        <TextLengthCounter
                                                            errorText={
                                                                rest.value.length >= 10001
                                                                    ? translate('MAX_LENGTH')
                                                                    : touched
                                                                    ? !!errors &&
                                                                      errors.action_title
                                                                    : ''
                                                            }
                                                            maxValue={10000}
                                                            value={rest.value}
                                                            colour={true}
                                                        />
                                                    }
                                                    onChange={(event) => {
                                                        setMaxLength(event.target.value.length)
                                                        onChange(event)
                                                    }}
                                                    {...rest}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                type="text"
                            />
                        </div>
                        <SelectSectionField
                            name="action_section"
                            sections={sections}
                            selectedSection={getPreselectedSectionId({
                                action,
                                selectedSection,
                                sections
                            } as any)}
                            isReadOnly={!!action.id}
                            error={!!errors && errors.action_section}
                            className={classes.sectionField}
                        />
                        <div className={classes.textFieldContainer}>
                            <DateField
                                error={!!errors && errors.action_date}
                                dateInputMask={dateInputMask}
                                className={classes.dateField}
                                disabled={!isAdmin}
                                labelText={'REVIEWER_DUE_DATE'}
                            />
                        </div>
                        <AssigneeChipInputField
                            isAdmin={isAdmin}
                            allContacts={allContacts}
                            hideAssigneeInfo={true}
                        />
                        {isAdmin && values.action_assignees?.length > 0 && (
                            <div className={classes.textFieldContainer}>
                                <Field
                                    name="self_manage"
                                    render={({ input: { onChange, checked, value, ...rest } }) => {
                                        return (
                                            <div className={classes.textFieldContainer}>
                                                <div style={{ width: '100%' }}>
                                                    <div className={classes.checkBoxContainer}>
                                                        <Checkbox
                                                            disableFocusRipple
                                                            disableRipple
                                                            className={classes.checkbox}
                                                            checked={checked}
                                                            aria-checked="mixed"
                                                            role="checkbox"
                                                            color="default"
                                                            disabled={!isAdmin}
                                                            onClick={() => onChange(!checked)}
                                                            onChange={onChange}
                                                            {...rest}></Checkbox>
                                                        <Typography
                                                            className={classes.checkboxTitle}
                                                            onClick={() => onChange(!checked)}>
                                                            {translate('SELF_MANAGE')}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }}
                                    type="checkbox"
                                />
                            </div>
                        )}
                        <div className={classes.textFieldContainer}>
                            <Field
                                name="action_notes"
                                render={({ input: { onChange, ...rest }, meta: { touched } }) => (
                                    <div className={classes.textFieldContainer}>
                                        <div style={{ width: '100%' }}>
                                            <InputLabel
                                                className={classes.inputLabel}
                                                htmlFor="action_notes">
                                                {translate('NOTES')}
                                            </InputLabel>
                                            <div>
                                                <NewDialogTextField
                                                    className={classes.textField}
                                                    multiline={true}
                                                    variant="outlined"
                                                    error={
                                                        !!touched &&
                                                        !!errors &&
                                                        !!errors.action_title
                                                    }
                                                    helperText={
                                                        <TextLengthCounter
                                                            errorText={
                                                                rest.value.length >= 10001
                                                                    ? translate('MAX_LENGTH')
                                                                    : touched
                                                                    ? !!errors &&
                                                                      errors.action_notes
                                                                    : ''
                                                            }
                                                            maxValue={10000}
                                                            value={rest.value}
                                                            colour={true}
                                                        />
                                                    }
                                                    onChange={(event) => {
                                                        setMaxLength(event.target.value.length)
                                                        onChange(event)
                                                    }}
                                                    {...rest}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                type="text"
                            />
                        </div>
                    </form>
                </>
            )}
        />
    )
}

export default ActionEditFormRightPanel
