/** @jsx jsx */

import { jsx } from '@emotion/react'
import { makeStyles } from '@material-ui/core'
import {
    ContentContainerhandleFocus,
    addKeyPressHandler,
    handleLinkBlur,
    handleLinkFocus
} from 'common/util/functions'
import { ColorBaseMulberryBlue, ColorBaseWhite } from 'assets/styles/variables'
import i18n from 'common/util/internationalization'

const useStyles = makeStyles(() => ({
    SkipToContentFocus: {
        '&:focus, &:active': {
            outline: 'none',
            boxShadow: `0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px ${ColorBaseMulberryBlue}`,
            borderRadius: '2px'
        }
    },
    SkipToContentRoot: {
        fontStyle: 'normal',
        position: 'absolute',
        top: '0px',
        padding: '16px 10px',
        backgroundColor: 'white',
        fontWeight: 600,
        fontSize: '16px',
        textAlign: 'center',
        color: '#385f99',
        flex: 'none',
        order: 0,
        flexGrow: 0,
        zIndex: 9999,
        clip: 'rect(0 0 0 0)'
    }
}))

export const SkipToContent = () => {
    const classes = useStyles()
    return (
        <a
            data-testid="skip-to-content"
            aria-label={i18n.t('SKIP_TO_CONTENT')}
            href="#ContentContainer_ContentBody"
            data-analytics="MM-SkipToContentLink"
            tabIndex={0}
            onKeyUpCapture={(e) => {
                if (addKeyPressHandler(e)) {
                    e.preventDefault()
                    ContentContainerhandleFocus()
                }
            }}
            onClick={(e) => {
                e.preventDefault()
                ContentContainerhandleFocus()
            }}
            onFocus={handleLinkFocus}
            onBlur={handleLinkBlur}
            className={`${classes.SkipToContentFocus} ${classes.SkipToContentRoot}`}>
            {i18n.t('SKIP_TO_CONTENT')}
        </a>
    )
}
export default SkipToContent
