import React, { useEffect, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { isSigninResult, SigninError } from '@diligent/sso-login/dist/index.js'
import ssoManagerFactoryService, {
    doLogout,
    doSwitchSite
} from '../../businesslayer/sso/SsoManagerFactoryService'

import {
    getLocalStorageItem,
    getSessionStorageItem,
    removeLocalStorageItem,
    removeSessionStorageItem,
    setLocalStorageItem,
    setSessionStorageItem
} from 'businesslayer/minutesLocalStore'
import { exchangeUuidToken } from 'businesslayer/api/identity'
import { StyledBody, StyledLogoWrapper } from 'components/shared/LockedModal'
import DiligentErrorIcon from 'assets/icons/DiligentErrorIcon'
import Button from '@material-ui/core/Button'
import { Box, makeStyles } from '@material-ui/core'
import styled from '@emotion/styled'
import Modal from 'react-modal'
import { ColorButtonHover } from 'assets/styles/variables'
import i18n from 'i18next'
import LinearIndeterminate from 'components/shared/LinearProgress'
import ssoConfig from '../../config/ssoConfig.json'

interface ISsoState {
    returnUrl: string
}

const StyledModal = styled(Modal)`
    position: absolute;
    top: 50%;
    gap: 48px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    outline: 0;
`

const customStyles = {
    overlay: {
        zIndex: 1000
    }
}

const sessionExpiredTextTitle = {
    fontWeight: 600,
    fontSize: '28px',
    lineHeight: '32px',
    height: '32px'
}

const sessionExpiredText = {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px'
}

const useStyles = makeStyles(() => ({
    customButton: {
        backgroundColor: `#455D82`,
        textTransform: 'none',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        height: '40px',
        borderRadius: '2px',
        textAlign: 'center',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: `${ColorButtonHover}`
        },
        color: '#FFFFFF'
    }
}))
const IdentityLoginCallback: React.FunctionComponent = () => {
    const history = useHistory()
    const { _env_ } = window
    //Note- This will only support Orca02 environment, please change its URL to work with other env.
    const SSO_AUTHORITY_URL =
        process.env.NODE_ENV !== 'development' ? _env_.SSO_AUTHORITY_URL : ssoConfig.authority
    const uuid = getSessionStorageItem('uuid')

    const CROSS_ENV_REDIRECT_URL_TEMPLATE = _env_.CROSS_ENV_REDIRECT_URL_TEMPLATE
    const [showErrorPage, setShowErrorPage] = useState(false)
    const [accessToken, setAccessToken] = useState<string>('')
    const classes = useStyles()

    const appendQueryStringToEnvironmentRedirectUrl = useCallback((baseUrl: string) => {
        const redirectUrl = new URL(baseUrl)
        const params = redirectUrl.searchParams

        if (!params.has('token')) {
            const userLanguage = getLocalStorageItem('userLanguage')
            const parentPlatform = getSessionStorageItem('parentPlatform')
            // We just need a token param to ensure the app component starts
            // the sign in process again, so just need to add a dummy value
            // if we don't already have the param
            params.append('token', 'environmentRedirect')
            params.append('userLanguage', userLanguage)
            params.append('parentPlatform', parentPlatform)
            setLocalStorageItem('landingPath', '/')
        }

        const newQuerystring = params.toString()
        const newRedirectUrl = `${redirectUrl.origin}${redirectUrl.pathname}?${newQuerystring}`
        return newRedirectUrl
    }, [])

    const getBaseEnvironmentRedirectUrl = useCallback(
        (signedInToEnvironment: string) => {
            const urlTemplate = CROSS_ENV_REDIRECT_URL_TEMPLATE
            let url = urlTemplate.replace('{ENVIRONMENT}', signedInToEnvironment).trim()

            if (!url.endsWith('/')) {
                url = `${url}/`
            }

            return url
        },
        [CROSS_ENV_REDIRECT_URL_TEMPLATE]
    )

    const getEnvironmentRedirectUrl = useCallback(
        (signedInToEnvironment: string) => {
            const baseUrl = getBaseEnvironmentRedirectUrl(signedInToEnvironment)
            const redirectUrl = appendQueryStringToEnvironmentRedirectUrl(baseUrl)

            return redirectUrl
        },
        [getBaseEnvironmentRedirectUrl, appendQueryStringToEnvironmentRedirectUrl]
    )

    const siteSwitch = (token: string) => {
        const signedInToEnvironment =
            token.indexOf(':') > -1 ? token.split(':')[0].toLowerCase() : ''
        const url = getEnvironmentRedirectUrl(signedInToEnvironment)
        setSessionStorageItem('environmentRedirect', 'false')
        window.location.href = url
    }
    const readSiteNameFromSessionStorage = () => {
        let siteName: any = null

        for (let i = 0; i < sessionStorage.length; i++) {
            const key = sessionStorage.key(i)
            if (key && key.includes('oidc.user')) {
                siteName = JSON.parse(sessionStorage.getItem(key) as string)
                siteName = siteName.profile.auth_site
                break // Stop after finding the first matching key
            }
        }

        if (siteName) {
            return siteName
        } else {
            return null // Return null if no matching key is found
        }
    }
    const handleSignedInNavigation = useCallback(
        (token: string) => {
            const signedInToEnvironment =
                token.indexOf(':') > -1 ? token.split(':')[0].toLowerCase() : ''

            const signedInToAuthorityEnvironment =
                signedInToEnvironment === ''
                    ? true
                    : SSO_AUTHORITY_URL.toLowerCase().indexOf(signedInToEnvironment) !== -1
            if (!signedInToAuthorityEnvironment) {
                if (!!uuid) {
                    setShowErrorPage(true)
                    return
                } else {
                    const url = getEnvironmentRedirectUrl(signedInToEnvironment)
                    setSessionStorageItem('environmentRedirect', 'false')
                    window.location.href = url
                }
            } else {
                let landingPath = getLocalStorageItem('landingPath')
                history.push(landingPath || '/')
                setSessionStorageItem('environmentRedirect', 'true')
            }
        },
        [SSO_AUTHORITY_URL, history, uuid, getEnvironmentRedirectUrl]
    )
    useEffect(() => {
        const signin = async () => {
            const ssoManager = await ssoManagerFactoryService.create()
            const result = await ssoManager.signinRedirectCallback<ISsoState>()

            if (!isSigninResult(result)) {
                switch (result) {
                    case SigninError.DoubleAuthorization:
                        return
                    case SigninError.Cancelled:
                    case SigninError.InvalidState:
                        await ssoManager.signinRedirect<ISsoState>({
                            state: {
                                returnUrl: '/'
                            }
                        })
                        return
                    default:
                        return
                }
            }

            const accessToken = result.user?.access_token

            setAccessToken(accessToken)

            if (localStorage.getItem('diligent.identity.authority')?.indexOf('') === -1) {
                doLogout()
            } else {
                if (process.env.NODE_ENV !== 'development') {
                    removeLocalStorageItem('sessionId')
                    removeSessionStorageItem('sessionId')
                    setLocalStorageItem('sessionId', result.user.access_token)
                    setAccessToken(result.user.access_token)
                    setSessionStorageItem('sessionId', result.user.access_token)
                    setSessionStorageItem('forbidden', 'false')
                } else {
                    removeLocalStorageItem('sessionId')
                    removeSessionStorageItem('sessionId')
                    setLocalStorageItem('sessionId', result.user.access_token)
                    setSessionStorageItem('sessionId', result.user.access_token)
                }
                sessionStorage.setItem('reloaded', 'false')

                const url = SSO_AUTHORITY_URL.toLowerCase()
                const sessionId = getLocalStorageItem('sessionId')

                if (url && sessionId) {
                    const env = sessionId.split(':')[0].toLowerCase()

                    // Check if the URL contains the environment name
                    if (url.toLowerCase().indexOf(env) !== -1 && uuid) {
                        const data = await exchangeUuidToken(
                            uuid,
                            exchangeUuidToken.bind(null, uuid)
                        )
                        const redirectUrl = new URL(data.url)
                        removeSessionStorageItem('uuid')
                        if (redirectUrl) {
                            window.location.href = redirectUrl.pathname
                            return
                        }
                    }
                }
                const landingPath = getLocalStorageItem('landingPath')
                const bookIdParam = getSessionStorageItem('bookId') as any
                const bookCommitteeIdParam = getSessionStorageItem('committeeId') as any
                setSessionStorageItem('authToken', 'false')
                if (landingPath === '/' && bookIdParam && bookCommitteeIdParam) {
                    const params = new URLSearchParams({
                        bookId: bookIdParam,
                        committeeId: bookCommitteeIdParam
                    }).toString()
                    removeSessionStorageItem('bookId')
                    history.push(`/?${params}`)
                } else {
                    handleSignedInNavigation(result.user.access_token)
                }
            }
        }

        signin()
    }, [handleSignedInNavigation, history, uuid, SSO_AUTHORITY_URL])

    if (!accessToken) {
        //A loading indicator or null until the accessToken is available
        return <LinearIndeterminate loading={true} />
    }

    if (showErrorPage) {
        let currentSite = readSiteNameFromSessionStorage()
        let targetSite = getSessionStorageItem('targetSiteName')
        return (
            <StyledModal isOpen={true} style={customStyles}>
                <StyledLogoWrapper>
                    <DiligentErrorIcon />
                </StyledLogoWrapper>
                <StyledBody>
                    <Box style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        <div style={sessionExpiredTextTitle}>{i18n.t('SITE_CHANGE_TILE')}</div>
                        <div style={sessionExpiredText}>
                            <p
                                style={{
                                    textAlign: 'center'
                                }}>
                                {i18n.t('SITE_CHANG_CURRENT_SITE', {
                                    currentSiteName: currentSite //sessionId?.split(':')[0].toLowerCase()
                                })}
                            </p>
                        </div>
                    </Box>
                </StyledBody>
                <Box display="flex" justifyContent="space-between" style={{ gap: '30px' }}>
                    <Button
                        className={classes.customButton}
                        onClick={() => siteSwitch(accessToken)}
                        style={{ textTransform: 'none', fontSize: '16px', fontWeight: 600 }}>
                        {i18n.t('STAY_ON_SITE')}
                    </Button>

                    <Button
                        className={classes.customButton}
                        onClick={() => doSwitchSite(targetSite)}>
                        {i18n.t('LEAVE', {
                            siteName: targetSite
                        })}
                    </Button>
                </Box>
            </StyledModal>
        )
    }

    return null
}

export default IdentityLoginCallback
