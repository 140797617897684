//#region Components
import React from 'react'
import styled from '@emotion/styled'
import { ClassNames, css } from '@emotion/react'
import { Field } from 'react-final-form'

import { translate } from 'components/shared/internationalization'

import { Divider, NewDialogTextField } from 'components/shared/StyledComponents'
import { MenuItem } from '@material-ui/core'
import i18n from 'i18next'
import { InputLabelStyled } from 'components/minutetaker/components/form/AssigneeChipInputField'

//#endregion

//#region Implementation

export const SelectSectionField = ({
    name,
    sections,
    selectedSection,
    isReadOnly,
    error,
    className
}: {
    name: string
    sections: any
    selectedSection?: any
    isReadOnly: boolean
    error: any
    className?: any
}) => {
    if (!sections) {
        //Sections may not yet be available right away.
        //Next time we reducer gets them prepared we renew them.
        sections = []
    }

    let items = fillSectionsItemsList(sections)

    addCreateNewItemOption(items)

    return (
        <Field
            name={name}
            render={({ input: { onChange, value /*, ...rest*/ }, meta: { touched } }) => {
                return (
                    <ClassNames>
                        {({ css }) => (
                            <SectionFieldContainer className={className}>
                                {isReadOnly ? (
                                    renderImmutableTextField(selectedSection, sections)
                                ) : (
                                    <div
                                        className={css`
                                            width: 100%;
                                            .MuiOutlinedInput-root {
                                                margin-top: 4px;
                                                padding-top: 4px;
                                                height: 40px;
                                            }
                                        `}>
                                        <InputLabelStyled
                                            required
                                            className={css`
                                                margin-top: -0.1rem;
                                            `}>
                                            {translate('SECTION_LABEL')}
                                        </InputLabelStyled>
                                        <NewDialogTextField
                                            select
                                            variant="outlined"
                                            id={i18n.t('SECTION_LABEL')}
                                            className={css`
                                                ${selectMenuCss}
                                            `}
                                            value={value}
                                            // label={translate('SECTION_LABEL')}
                                            onChange={(section) => onChange(section)}
                                            error={touched && error}
                                            helperText={touched && error}
                                            InputProps={{ placeholder: 'blah' }}>
                                            {items}
                                        </NewDialogTextField>
                                    </div>
                                )}
                            </SectionFieldContainer>
                        )}
                    </ClassNames>
                )
            }}
            type="select"
        />
    )
}

const fillSectionsItemsList = (sections: Array<any> = []) => {
    //At some point newly created section coming here when users choose
    //to create new section but dialog not yet closed starting saving action
    //We dont want to show that section in here (we only want to show it when it is actually saved)
    sections = sections.filter((val) => val.id && val.sectionType === 'minutes')

    const items = sections.map((val) => {
        const name = val.name && val.name.length > 45 ? `${val.name.substr(0, 45)}...` : val.name
        return (
            <MenuItem key={val.id} value={val.id}>
                {name}
            </MenuItem>
        )
    })

    return items
}

const addCreateNewItemOption = (items: Array<any>) => {
    //Add item for creating new section
    if (items.length > 0) {
        items.push(<Divider key={0} />)
    }

    items.push(
        <MenuItem key={1} value={'-1'}>
            {translate('CREATE_NEW_SECTION_DROPDOWN')}
        </MenuItem>
    )
}

const renderImmutableTextField = (valueId: string, items: Array<any>): JSX.Element => {
    //For existing actions we cannot change section so far
    const section = valueId && items.find((c) => c.id === valueId)
    const text = section ? section.name : ''

    return (
        <ClassNames>
            {({ css }) => (
                <div
                    className={css`
                        width: 100%;
                        .MuiOutlinedInput-root {
                            margin-top: 4px;
                            height: 40px;
                        }
                    `}>
                    <InputLabelStyled
                        className={css`
                            display: inline-block;
                        `}>
                        {translate('SECTION_LABEL')}
                    </InputLabelStyled>
                    <NewDialogTextField
                        disabled={true}
                        value={text}
                        variant="outlined"
                        className={css`
                            ${selectMenuCss}
                        `}
                        // label={translate('SECTION_LABEL')}
                        id={valueId ? valueId : ''}
                        classes={{
                            inputProps: css`
                                color: rgba(0, 0, 0, 0.38);
                            `
                        }}
                    />
                </div>
            )}
        </ClassNames>
    )
}

//#endregion

//#region Styles

const SectionFieldContainer = styled('div')`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 48px;
`
const selectMenuCss = css`
    .MuiSelect-selectMenu {
        padding-top: 13px;
    }
`
//endregion

export default SelectSectionField
