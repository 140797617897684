//#region Global Helpers

export const guid = () => {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1)
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
}

//#endregion

//#region Notification Dialog

export type ActionNotificationTo = {
    text: string
    email: string
    id: string | null
    isInverted: boolean
    value: any
}
export type ActionNotification = {
    committee_id?: any
    platform: string | null
    to: Array<ActionNotificationTo> | null
    subject: string
    body: string | null
    minutesId: string | null
    actionId: string | null
    validationErrors: Map<string, string> | null
    invalidEmails: Map<string, string> | null
}

//#endregion

//#region Notification Dialog
export type ActionListSort = {
    name: string
    ascending: boolean
}
export type Details = {
    id: string
    action_title: string
    notificationStatus: string
    due_date: string | null
    completed_date?: string | null
    createdAt: string
    status: string
    committeeName: string
    committee: string
    committee_id: string
    sections?: any[]
    minutes_section_id?: string
    minutes_document_id?: string
}
export type CurrentMinutesProps = {
    id: string
    attributes: {
        display_name: string
        first_name?: string
        last_name?: string
        email: string
        due_date?: Date
        review_completed: boolean
        userId: number
        committeeName?: string
    }
}
export interface MinutesSectionsData {
    minutesId: string
    id: string
    name: string
    order: number
    level: number | null
    body: any
    html: string
    sectionType: string
    updatedAt: string
    loaded: boolean
}
export interface ActionData {
    action_title: string
    minutes_meeting: string
    due_date: string
    completed_date: string
    assigned_to: string
    status: string
    notificationStatus: string
    committeeName: string
    notes: any
    id: string
    sortOrder: number
    dueDate: string | null
    minutesId: number
    minutesSectionId: number
    text: string
    completionStatus: string
    assignees: { email: string; id: number; text: string }[]
    createdAt: string
    updatedAt: string
}

//#endregion
