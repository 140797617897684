/**@jsx jsx */

import { FC, useMemo } from 'react'
import { jsx, css } from '@emotion/react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { AtlasIcon } from 'web-components/atlas-icon'
import { getAtlasHelperText } from '../text-field/input-error-text'
import { MenuItem } from '@material-ui/core'
import { atlasSelectMenuStyles } from 'material-atlas/overrides/mui-menu'
import { TextFieldProps } from '@material-ui/core/TextField'
import { makeMuiFieldAdapter } from 'components/final-form/final-form-helper'
import { NewDialogTextField } from 'components/shared/StyledComponents'

export const PLACEHOLDER_KEY = 'NONE'
const useMenuStyles = makeStyles(atlasSelectMenuStyles)

export const AtlasSelect: FC<TextFieldProps & TestIdAttribute & AccessibilityAttribute> = ({
    value,
    placeholder,
    helperText,
    children,
    onChange,
    'data-testid': data_testid,
    role,
    ariaLabelledby,
    variant,
    'data-analytics': dataAnalytics,
    ...props
}) => {
    const valueOrPlaceholder = !!value ? value : !!placeholder ? PLACEHOLDER_KEY : ''
    const selectMenuClasses = useMenuStyles()
    const selectProps = useMemo(() => {
        return {
            MenuProps: {
                classes: selectMenuClasses
            },
            SelectDisplayProps: {
                'data-testid': data_testid,
                role: role,
                'aria-labelledby': ariaLabelledby,
                'data-analytics': dataAnalytics
            },
            IconComponent: AtlasSelectIcon
        }
    }, [selectMenuClasses, data_testid, role, ariaLabelledby, dataAnalytics])

    const childrenWithPlaceholder = useMemo(() => {
        return [SelectPlaceholder(placeholder), children]
    }, [children, placeholder])
    const AtlasHelperTextComponent = useMemo(() => getAtlasHelperText({ errorText: helperText }), [
        helperText
    ])
    return (
        <NewDialogTextField
            select
            value={valueOrPlaceholder}
            helperText={AtlasHelperTextComponent}
            SelectProps={selectProps as any}
            data-analytics={dataAnalytics}
            variant={variant}
            onChange={(e) => {
                !!onChange && onChange(e)
                // Calling onBlur here helps validate the dropdown
                // immediately after selecting an option
                !!props.onBlur && props.onBlur(e as any)
            }}
            {...props}>
            {childrenWithPlaceholder}
        </NewDialogTextField>
    )
}

const AtlasSelectIcon = (props) => (
    <AtlasIcon
        name="expand-down"
        css={css`
            position: absolute;
            top: calc(50% - 12px);
            right: 0;
            margin-right: 12px;
            cursor: pointer;
        `}
        {...props}
    />
)
/**
 * In order to put a placeholder in the select field
 * there has to be an existing menu item with that text.
 * We can put a hidden menu item with a specific value that
 * will be used as a placeholder when no value is provided.
 */
const SelectPlaceholder = (placeholder: string | undefined) =>
    !!placeholder ? (
        <MenuItem
            key="hidden-placeholder"
            value={PLACEHOLDER_KEY}
            disabled
            css={css`
                display: none;
            `}>
            {placeholder}
        </MenuItem>
    ) : null

/**
 * final-form adapter for AtlasSelect
 */
export const AtlasSelectAdapter = makeMuiFieldAdapter<TextFieldProps>(AtlasSelect)
