/** @jsx jsx */
import StatusPopover from './StatusPopover'
import PopoverButton from 'components/shared/PopoverButton'
import { css } from '@emotion/react'
import { jsx } from '@emotion/react'
import { pathOr } from 'rambdax'
import React from 'react'
import { translate } from 'components/shared/internationalization'
import {
    ColorBaseBlueGreyMedium,
    ColorBaseMulberryBlue,
    ColorBaseSkyBlue,
    ColorBaseWhite,
    ColorEastBay
} from 'assets/styles/variables'

import { PopoverButton as StyledPopoverButton } from 'components/shared/StyledComponents'
import { Subtitle } from 'components/shared/typography/Typography'
import i18n from 'i18next'
import { makeStyles } from '@material-ui/core'
import RevealListIcon from 'assets/icons/RevealListIcon'

type Props = {
    className?: string
    statusField: DocumentStatusKey
    onStatusChange?: any
    isIconOnly?: boolean
    isDisabled?: boolean
}

export function StatusPopoverButton({ isIconOnly = false, ...props }: Props) {
    const buttonDetailsMap = {
        draft: {
            text: translate('DRAFT')
        },
        in_review: {
            text: translate('IN_REVIEW')
        },
        final_draft: {
            text: translate('FINAL_DRAFT')
        },
        approved: {
            text: translate('APPROVED')
        }
    }
    const useStyles = makeStyles(() => ({
        draftPopoverButton: {
            minWidth: '99px',
            padding: '8px 12px 8px 12px',
            marginLeft: '16px',
            fontFamily: 'Source Sans Pro',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '20px',
            height: '40px',
            color: ColorBaseWhite,
            backgroundColor: props.isDisabled ? '#e6e6e6' : ColorEastBay,
            borderRadius: '2px',
            textWrap: 'nowrap',
            '&:hover': {
                backgroundColor: '#364262',
                '& path': {
                    stroke: 'ColorBaseWhite'
                }
            },
            '&:focus, &:active': {
                boxShadow: `0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px ${ColorBaseMulberryBlue}`,
                borderRadius: '2px'
            },
            '& path': {
                stroke: ColorBaseWhite
            },
            '& span': {
                gap: '8px',
                fontFamily: 'Source Sans Pro',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '20px'
            }
        }
    }))
    const classes = useStyles()

    const buttonText = pathOr(
        buttonDetailsMap.draft.text,
        props.statusField ? [`${props.statusField}`, 'text'] : [''],
        buttonDetailsMap
    )

    return (
        <PopoverButton>
            {({ onShowPopover, isOpen, onClosePopover, anchorElement }) => {
                return (
                    <React.Fragment>
                        <StyledPopoverButton
                            className={classes.draftPopoverButton}
                            data-testid="DocumentStatusPopoverBtn"
                            data-analytics={`MM-SetStatus-${props.statusField}`}
                            aria-label={`${i18n.t('MINUTES_STATUS')}: ${
                                props.statusField
                            }. ${i18n.t('SELECT_TO_CHANGE_STATUS')}`}
                            disableFocusRipple
                            disabled={props.isDisabled}
                            aria-expanded={isOpen ? 'true' : 'false'}
                            onClick={onShowPopover}>
                            <Subtitle type="subtitle1">{buttonText}</Subtitle>
                            <RevealListIcon />
                        </StyledPopoverButton>
                        <StatusPopover
                            anchorElement={anchorElement}
                            isOpen={isOpen}
                            onClosePopover={onClosePopover}
                            onStatusChange={props.onStatusChange}
                            onRequestCloseExportPopover={onClosePopover}
                            closeReason={props.statusField}
                        />
                    </React.Fragment>
                )
            }}
        </PopoverButton>
    )
}

export const PopoverButtonCss = css`
    margin-right: 12px;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    background-color: ${ColorBaseWhite};
    color: ${ColorEastBay};
    height: 40px;
    border: 1px solid ${ColorEastBay};
    &:focus,
    &:active {
        height: 40px;
        box-shadow: 0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px ${ColorBaseMulberryBlue};
        border-radius: 2px;
    }
    &:hover {
        height: 40px;
        background-color: #9494945;
    }
`
export const UtilityIconButtonCss = css`
    height: 50px;
    color: ${ColorBaseBlueGreyMedium};
    &:focus,
    &:active {
        box-shadow: 0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px ${ColorBaseSkyBlue};
        border-radius: 2px;
    }
`
