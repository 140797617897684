import moment from 'moment'

//:unsent, :sent, :completed, :overdue
const templateObject = (): any => {
    return {
        data: {
            type: 'action_items',
            attributes: {
                minutes_section_id: null,
                sort_order: -1,
                text: '',
                note: null,
                notification_status: 'unsent',
                completion_status: 'incomplete',
                due_date: null,
                assignees: null
            }
        }
    }
}

const templateActionReorder = (): any => {
    return {
        data: {
            type: 'action_items',
            id: -1,
            attributes: {
                minutes_section_id: -1,
                sort_order: -1
            }
        }
    }
}

const getAssignees = (item) => {
    if (!item.assignees || !item.assignees.length) {
        return null
    }
    const formatted = item.assignees.map((assignee) => {
        let id = typeof assignee.value === 'number' ? parseInt(assignee.value, 10) : null
        if (assignee.id) {
            id = assignee.id
        }
        return {
            id: id,
            email: assignee.email || null,
            display_name: assignee.text,
            self_manage: assignee.self_manage
        }
    })
    return formatted
}

export const serialize = (item) => {
    const requestData = templateObject()
    requestData.data.attributes.text = item.text
    requestData.data.attributes.note = item.notes
    requestData.data.attributes.notification_status = item.notificationStatus
    requestData.data.attributes.completion_status = item.completionStatus
    requestData.data.attributes.sort_order = item.sortOrder
    requestData.data.attributes.minutes_section_id = item.minutes_section_id
    requestData.data.attributes.minutesId = item.minutesId
    requestData.data.attributes.due_date =
        item.dueDate && moment(item.dueDate).isValid()
            ? moment(item.dueDate).toDate().toISOString()
            : item.dueDate ?? null
    requestData.data.attributes.assignees = getAssignees(item)

    return JSON.stringify(requestData)
}

export const serializeActions = (items) => {
    const requestData: { data: any[] } = { data: [] }
    items.forEach((item) => {
        const actionItem: { data: any } = templateActionReorder()
        actionItem.data.id = item.id
        actionItem.data.attributes.sort_order = item.sortOrder
        actionItem.data.attributes.minutes_section_id = item.minutesSectionId

        requestData.data.push(actionItem.data)
    })

    return JSON.stringify(requestData)
}
