import {
    validateMeetingEndDate,
    validateMeetingStartDate,
    validateMinutesCommitteeField
} from 'common/util/validators/minutes-properties/minutes-properties-validators'
import { FormBody } from 'components/layout/modal-form-layout'
import { CommitteeSelect } from 'components/popovers/committee-select/committee-select'
import React, { FC, useEffect, useState, useMemo } from 'react'
import { Form, Field, FormSpy } from 'react-final-form'
import { useCommitteesState } from 'components/contexts/committee-context'
import { AtlasDatePickerAdapter } from 'components/date-time-pickers/date-picker'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { CssBaseline, Paper, Box } from '@material-ui/core'
import i18n from 'common/util/internationalization'
import { getAtlasHelperText } from 'components/text-field/input-error-text'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions } from 'reducers/minutesAttendeesReducer'
import { ColorBaseSkyBlue } from 'assets/styles/variables'
import { css } from '@emotion/css'
import { useMinutesOfCommittee } from 'businesslayer/networkrequests/minutes/use-minutes-of-committee'
import { filterDatesBetween } from 'common/util/functions'
import { initialState } from 'reducers/minutesAttendeesReducer'

type Props = {
    onHandleSubmit: () => void // Adjust this type if possible
    setCanSaveMinutes: React.Dispatch<React.SetStateAction<boolean>>
    canSaveMinutes: boolean
    actions: typeof actions
    reportData: any
    setReportData: any
}
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2)
    },
    labelCss: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '20px',
        '& .Mui-focusVisible': {
            boxShadow: `0px 0px 0px 2px ${ColorBaseSkyBlue}`,
            borderRadius: '2px'
        },
        '& button': {
            height: '30px',
            width: '30px'
        }
    },
    gapcss: {
        rowGap: '1rem'
    },
    dateRangechild: {
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '16px'
    },
    startDateContainer: {
        width: '100%',
        padding: '0px 2px 0px 0px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '5px'
    },
    LabelCss: {
        '& .Mui-focusVisible': {
            boxShadow: `0px 0px 0px 2px ${ColorBaseSkyBlue}`,
            borderRadius: '2px'
        },
        '& button': {
            height: '30px',
            width: '30px'
        }
    },
    dateRangeTextContainer: {
        marginBottom: '1rem',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1rem'
    }
}))
const MainInfoSection: FC<Props> = (props) => {
    const classes = useStyles()
    const { adminCommittees } = useCommitteesState()
    const {
        onHandleSubmit,
        setCanSaveMinutes,
        canSaveMinutes,
        actions,
        reportData,
        setReportData
    } = props

    const [errorData, setErrorData] = useState<any>({})
    const [committeeData, setCommitteeData] = useState<any>()

    const [isFetchData, setIsFetchData] = useState(false)

    useMemo(() => {
        const isValidFetch = committeeData?.id ? true : false
        setIsFetchData(isValidFetch)
    }, [committeeData])

    const { data: selectectedCommitteArr, isValidating } = useMinutesOfCommittee(
        committeeData?.id,
        isFetchData
    )
    const [memoInitialValues] = useState({
        committee: {
            id: 'NONE',
            name: ''
        },
        startDate: null,
        endDate: null
    })
    const newReportData = initialState.dataState.newReportData
    useEffect(() => {
        if (reportData.committee?.id !== 'NONE') {
            if (canSaveMinutes && reportData.endDate !== null && selectectedCommitteArr) {
                setIsFetchData(false)
                const filteredDate = filterDatesBetween(
                    reportData.startDate,
                    reportData.endDate,
                    selectectedCommitteArr
                )

                actions.createNewReport({
                    ...newReportData,
                    ...{
                        selectedCommittee: [...filteredDate],
                        committeeId: committeeData?.id,
                        committeeName: committeeData?.name,
                        startDate: reportData.startDate,
                        endDate: reportData.endDate
                    }
                })
            } else if (selectectedCommitteArr) {
                setIsFetchData(false)
                actions.createNewReport({
                    ...newReportData,
                    ...{
                        selectedCommittee: [...selectectedCommitteArr],
                        committeeId: committeeData?.id,
                        committeeName: committeeData?.name
                    }
                })
            } else {
                actions.createNewReport(newReportData)
            }
        }
    }, [reportData, actions, canSaveMinutes, selectectedCommitteArr, committeeData, newReportData])

    useEffect(() => {
        actions.setMeetingsLoading(isValidating)
    }, [actions, isValidating])

    return (
        <Paper className={classes.root} elevation={0}>
            <CssBaseline />
            <Form
                onSubmit={onHandleSubmit}
                initialValues={memoInitialValues}
                subscription={{ submitting: true, pristine: true }}
                render={({ handleSubmit }) => (
                    <FormBody id="formBody" onSubmit={handleSubmit} className={classes.gapcss}>
                        <FormSpy
                            onChange={({ valid, values, errors }) => {
                                setTimeout(() => {
                                    if (values.committee.id !== 'NONE') {
                                        setCommitteeData(values.committee)
                                    }
                                    setCanSaveMinutes(valid)
                                    setReportData({ ...values })
                                    setErrorData({ ...errors })
                                }, 0)
                            }}
                        />
                        <Box width="calc(100% - 5px)">
                            <Field name="committee" validate={validateMinutesCommitteeField}>
                                {({ input: { onChange, ...inputProps } }) => {
                                    return (
                                        <CommitteeSelect
                                            onChange={(value) => {
                                                onChange(value)
                                            }}
                                            isReadonly={false}
                                            committees={adminCommittees}
                                            {...inputProps}
                                        />
                                    )
                                }}
                            </Field>
                        </Box>
                        <Box className={classes.dateRangeTextContainer}>
                            <Box className={classes.labelCss}>{i18n.t('DATE_RANGE')}</Box>
                            <span className={classes.dateRangechild}>
                                {i18n.t('DATE_RANGE_CONTENT')}
                            </span>
                        </Box>
                        <Box className={classes.startDateContainer}>
                            <Field
                                InputLabelProps={{ style: { color: 'inherit' } }}
                                name={`startDate`}
                                label={i18n.t('START_DATE_LABEL.default')}
                                data-testid="MinutesProperties_MeetingDatePicker"
                                validate={validateMeetingStartDate}
                                data-analytics="MM-Filter-StartDate"
                                render={AtlasDatePickerAdapter}
                                className={classes.LabelCss}
                                helperText={null} // Text will be displayed at row level
                                showErrorWhen={true}
                            />
                            {getAtlasHelperText({ errorText: errorData.startDate })}
                        </Box>

                        <Box className={classes.startDateContainer}>
                            <Field
                                InputLabelProps={{ style: { color: 'inherit' } }}
                                name="endDate"
                                label={i18n.t('END_DATE_LABEL.default')}
                                className={css`
                                    ${LabelCss}
                                `}
                                data-testid="MinutesProperties_MeetingDatePicker"
                                data-analytics="MM-Filter-EndDate"
                                validate={validateMeetingEndDate}
                                render={AtlasDatePickerAdapter}
                                helperText={null} // Text will be displayed at row level
                                showErrorWhen={true}
                            />
                            {getAtlasHelperText({ errorText: errorData.endDate })}
                        </Box>
                    </FormBody>
                )}></Form>
        </Paper>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}
export default connect(null, mapDispatchToProps)(MainInfoSection)
const LabelCss = css`
    .Mui-focusVisible {
        box-shadow: 0px 0px 0px 2px ${ColorBaseSkyBlue};
        border-radius: 2px;
    }
    button {
        height: 30px;
        width: 30px;
    }
`
