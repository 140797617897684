import React, { Component } from 'react'
import styled from '@emotion/styled'
//================ Components ===============================

import { translate } from 'components/shared/internationalization'
import i18n from 'i18next'

import InlineError from '../../../shared/InlineError'
//#region Components Material

import {
    ColorBaseDarkGray,
    ColorBaseMulberryBlue,
    ColorBaseRichBlack,
    FontWeightRegular,
    AtlasGreyLight,
    ColorBaseWhite
} from 'assets/styles/variables'

import { Field } from 'react-final-form'
import ChipInputField from 'components/chip-input/chip-input-field'
import { ClassNames, css } from '@emotion/react'
import { InputLabel } from '@material-ui/core'

//#endregion

//#region Props / State

type Props = {
    allContacts: any
    hideAssigneeInfo?: boolean
    isAdmin?: boolean
}

type State = {
    assigneeError: string | null
}

//#endregion

//#region Implementation

export class AssigneeChipInputField extends Component<Props, State> {
    state = {
        assigneeError: ''
    }

    private onAssigneeAdd = ({ newObject, onChange, assignees }) => {
        if (!newObject.text || !newObject.text.trim()) {
            return
        }
        const { allContacts } = this.props

        if (!allContacts) {
            return
        }

        if (!assignees) {
            assignees = []
        }

        const newAssignees: any[] = [...assignees]

        const newAssignee = adjustNewAssigneeObject(allContacts, newObject)

        if (newAssignees.length >= 100) {
            //We cannot add more than certain amount of folks
            this.setState({ assigneeError: i18n.t('INVALID_ASSIGNEES_COUNT') })
            setTimeout(() => this.setState({ assigneeError: null }), 3000)
            return
        }

        newAssignees.push(newAssignee)
        onChange(newAssignees)
    }

    private onAssigneeDelete = ({ toDelete, onChange, assignees }) => {
        if (!toDelete) {
            return
        }

        const item = assignees.find((existing) => existing.value === toDelete.value)

        if (!item) {
            return
        }
        const index = assignees.indexOf(item)
        const newAssignees: any[] = [...assignees]
        newAssignees.splice(index, 1)
        onChange(newAssignees)
    }

    handleBlur = ({ event, onChange, assignees }) => {
        if (!!event.target.value) {
            const isUnique = assignees.every((assignee) => {
                return event.target.value.toLowerCase() !== assignee.text.toLowerCase()
            })
            if (isUnique) {
                const newObject = {
                    text: event.target.value,
                    value: event.target.value
                }
                this.onAssigneeAdd({ newObject, onChange, assignees })
            }
        }
    }

    makeOnChipChange = ({ addChip, deleteChip }) => ({ options, action, onChange, assignees }) => {
        if (action.action === 'select-option') {
            addChip({
                newObject: action.option,
                onChange,
                assignees
            })
        } else if (action.action === 'create-option') {
            addChip({
                newObject: options[options.length - 1],
                onChange,
                assignees
            })
        } else if (['remove-value', 'pop-value'].includes(action.action)) {
            deleteChip({
                toDelete: action.removedValue,
                onChange,
                assignees
            })
        }
    }

    render() {
        const { allContacts, isAdmin } = this.props
        const assigneeError = getAssigneeError(this.state.assigneeError)
        const floatingLabelText = (
            <>{/* {translate('ASSIGNEES')} - {<Italic>{translate('OPTIONAL_LABEL')}</Italic>} */}</>
        )
        return (
            <Field
                name="action_assignees"
                render={({ input: { onChange, value: assignees } }) => {
                    const chipInputValue = getExistingAssigneesData(assignees)
                    return (
                        <ClassNames>
                            {({ css }) => (
                                <StyledAssigneeInput>
                                    <AssigneeControlWrapper>
                                        <div
                                            className={css`
                                                ${AssigneeTextField}
                                            `}>
                                            <div
                                                className={css`
                                                    display: flex;
                                                    flex-direction: column;
                                                `}>
                                                <InputLabelStyled
                                                    className={css`
                                                        margin-top: 1rem;
                                                    `}>
                                                    {i18n.t('ASSIGNEES')}
                                                </InputLabelStyled>
                                                <ChipInputField
                                                    variant="outlined"
                                                    className={css`
                                                        ${Assignees}, ${adminStyles(
                                                            isAdmin ?? false
                                                        )}
                                                    `}
                                                    aria-label={i18n.t('ASSIGNEES')}
                                                    options={getAssigneesDataSource(
                                                        allContacts,
                                                        assignees
                                                    )}
                                                    value={chipInputValue}
                                                    isDisabled={isAdmin === true ? false : true}
                                                    floatingLabelText={floatingLabelText}
                                                    onChange={(options, action) =>
                                                        this.makeOnChipChange({
                                                            addChip: this.onAssigneeAdd,
                                                            deleteChip: this.onAssigneeDelete
                                                        })({ options, action, onChange, assignees })
                                                    }
                                                    // onBlur={(event) => {
                                                    //     this.handleBlur({ event, onChange, assignees })
                                                    // }}
                                                />
                                            </div>
                                        </div>
                                        {assigneeError}
                                        {!this.props.hideAssigneeInfo && (
                                            <AssigneeDisclaimer>
                                                {translate('ASSIGNEES_INFO_TEXT')}
                                            </AssigneeDisclaimer>
                                        )}
                                    </AssigneeControlWrapper>
                                </StyledAssigneeInput>
                            )}
                        </ClassNames>
                    )
                }}
            />
        )
    }
}

//Note: the set of functions used in main action editor dialog
export const filterNotAddedAssignees = (allContacts: Array<any>, assignees: any) => {
    const keys = Object.keys(allContacts)

    if (!assignees) {
        assignees = []
    }
    //Dont show ones we already added
    const filtered = keys.filter(
        (key) =>
            !assignees.find((existing) => {
                return existing.text === allContacts[key].attributes.displayName
            })
    )

    return filtered
}

export const getAssigneesDataSource = (allContacts: Array<any> | null, assignees: any) => {
    if (!allContacts) {
        return
    }

    const availableAssignees = filterNotAddedAssignees(allContacts, assignees)

    const result = availableAssignees.map((key) => {
        const item = allContacts[key]

        return {
            text: item.attributes.displayName,
            email: item.attributes.email,
            id: item.id,
            value: item.attributes.displayName
        }
    })

    return result
}

export const getExistingAssigneesData = (assignees: any) => {
    if (!assignees) {
        assignees = []
    }

    return assignees.map((item) => {
        //const itemText = getAssigneeText(item)
        return {
            text: item.text,
            email: item.email,
            id: item.id,
            value: item.text
        }
    })
}

export const adjustNewAssigneeObject = (allContacts: Array<any>, newObject: any) => {
    let newAssignee = newObject

    //BREAKING CHANGE Since 1.0: Control no longer sends the same object from autoselect so we have to
    //preprocess it to find out if that is our object
    if (!newObject.email && typeof newObject.value === 'object') {
        const contact = allContacts[newObject.value.key]

        newAssignee = {
            text: newObject.text,
            email: contact.attributes.email,
            id: newObject.value.key,
            value: newObject.value
        }
    }

    return newAssignee
}

export const getAssigneeError = (assigneeErrorText: string | null) => {
    return (
        assigneeErrorText && (
            <section>
                <InlineError error={assigneeErrorText} />
            </section>
        )
    )
}

//#endregion

//#region Styles
const InputLabelStyled = styled(InputLabel)`
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: ${FontWeightRegular};
    font-size: 13px;
    line-height: 16px;
    color: ${ColorBaseRichBlack};
`

export const adminStyles = (isAdmin: boolean) => css`
    .MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth {
        background-color: ${isAdmin ? `${ColorBaseWhite}` : `${AtlasGreyLight}`};
    }
`

export const Assignees = css`
    .MuiOutlinedInput-notchedOutline {
        border: 1px solid #949494;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${ColorBaseRichBlack};
        box-shadow: 0px 0px 0px 2px ${ColorBaseMulberryBlue};
    }
    .MuiOutlinedInput-root {
        padding: 0px 20px 0px 16px;
    }
    .MuiChip-label {
        font-family: 'Source Sans Pro';
        font-size: 12px;
        font-weight: ${FontWeightRegular};
        font-style: normal;
        line-height: 14px;
        color: ${ColorBaseRichBlack};
    }

    .MuiButtonBase-root.MuiChip-root {
        border: 1px solid rgba(0, 0, 0, 0.23);
        height: 24px;
        margin-top: 8px;
        margin-bottom: 8px;
        color: ${ColorBaseDarkGray};
        border-color: var(--grey-dark);
        background-color: transparent;
        &:hover {
            background-color: #ebeef2;
            border-radius: 12px;
        }
    }

    .MuiChip-deleteIcon {
        display: inline-block;
        line-height: 0;
        width: 16px;
        height: 16px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
`
export const AssigneeTextField = css`
    .MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth {
        margin-top: 8px;
    }
    .MuiOutlinedInput-root {
        min-height: 40px;
    }
`

const StyledAssigneeInput = styled('div')`
    justify-content: flex-start;
    align-items: flex-start;
`

const AssigneeControlWrapper = styled('div')`
    width: 100%;
`

const AssigneeDisclaimer = styled('div')`
    font-family: 'Source Sans Pro';
    font-size: 13px;
    font-weight: ${FontWeightRegular};
    font-style: normal;
    line-height: 16px;
    opacity: 0.5;
    font-size: 12px;
    padding-top: 8px;
    padding-left: 0px;
    color: ${ColorBaseDarkGray};
`

//#endregion

//#region Export

export { InputLabelStyled }
export default AssigneeChipInputField

//#endregion
