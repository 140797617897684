/** @jsx jsx */
/**
 * Items used to compose Toolbars
 */
import { translate } from 'components/shared/internationalization'
import i18n from 'i18next'
import NewMinutesIcon from 'assets/icons/NewMinutesIcon'
import { ColorBaseMulberryBlue, ColorBaseWhite, ColorEastBay } from 'assets/styles/variables'
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { Button, makeStyles } from '@material-ui/core'

export const Separator = styled('div')`
    height: 70%;
    border-right: 2px solid #e0e0e0;
    margin-right: 10px;
    margin-left: 10px;
`

export const AddSectionButton = ({
    showLabels,
    onClick
}: {
    showLabels: boolean
    onClick?: () => void
}) => {
    const classes = useStyles()
    return (
        <Button
            disableFocusRipple
            disableRipple
            className={classes.buttonStyle}
            onClick={onClick}
            data-analytics="MM-AddSection"
            aria-label={i18n.t('ADD_SECTION_ITEM')}>
            {showLabels && translate('ADD_SECTION_ITEM')}
        </Button>
    )
}
export const AddActionButton = ({
    showLabels,
    onClick
}: {
    showLabels: boolean
    onClick?: () => void
}) => {
    const classes = useStyles()
    return (
        <Button
            disableFocusRipple
            disableRipple
            className={classes.buttonStyle}
            onClick={onClick}
            aria-haspopup="dialog"
            data-analytics="MM-AddAction"
            aria-label={i18n.t('ADD_ACTION_ITEM')}>
            {showLabels && translate('ADD_ACTION_ITEM')}
        </Button>
    )
}

export const NewMinutesButton = ({
    isCollapsed,
    onAddMinutes
}: {
    isCollapsed?: boolean
    onAddMinutes: () => void
}) => {
    const classes = useStyles()
    return (
        <Button
            disableFocusRipple
            disableRipple
            className={classes.buttonStyle}
            onClick={onAddMinutes}>
            <NewMinutesIcon />
            {!isCollapsed && translate('ADD_NEW')}
        </Button>
    )
}

const useStyles = makeStyles(() => ({
    buttonStyle: {
        textTransform: 'none',
        cursor: 'pointer',
        maxWidth: '235px',
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
        lineHeight: '20px',
        textAlign: 'center',
        alignItems: 'center',
        display: 'flex',
        padding: '8px 12px 8px 12px',
        '@media (max-width: 980px)': {
            minWidth: '40px'
        },
        border: '2px solid transparent',
        borderRadius: '2px',
        '&:focus, &:active': {
            boxShadow: `0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px ${ColorBaseMulberryBlue}`,
            borderRadius: '2px'
        },
        '&:hover': {
            backgroundColor: '#364262'
        },

        height: '40px',
        color: '#fff',
        backgroundColor: ColorEastBay,
        fontSize: '16px'
    },
    newMinutesIcon: {
        height: '1.2rem',
        width: '1.2rem',
        fill: 'white',
        marginRight: '4px'
    }
}))
