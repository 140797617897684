import { createAction, handleActions } from 'redux-actions'
import { Reducer } from 'redux'
import { actionComplete, saveAction } from 'businesslayer/api/actions'

const DASHBOARD_SORT_ACTIONS = 'DASHBOARD_SORT_ACTIONS'
const DASHBOARD_RESET_SORT = 'DASHBOARD_RESET_SORT'
const DASHBOARD_LOAD_ACTIONS = 'DASHBOARD_LOAD_ACTIONS'
const DASHBOARD_LOAD_ACTIONS_MORE = 'DASHBOARD_LOAD_ACTIONS_MORE'
const DASHBOARD_EDIT_ACTION = 'DASHBOARD_EDIT_ACTION'
const DASHBOARD_EDIT_ACTION_FULFILLED = 'DASHBOARD_EDIT_ACTION_FULFILLED'
const DASHBOARD_COMPLETE_ACTION = 'DASHBOARD_COMPLETE_ACTION'
const DASHBOARD_DELETE_ACTION = 'DASHBOARD_DELETE_ACTION'
const DASHBOARD_COMPLETE_FULFILLED = 'DASHBOARD_COMPLETE_FULFILLED'
const DASHBOARD_NOTIFICATION = 'DASHBOARD_NOTIFICATION'

export const actions = {
    sortActions: createAction<void>(DASHBOARD_SORT_ACTIONS),
    resetSort: createAction<void>(DASHBOARD_RESET_SORT),
    loadActions: createAction<void>(DASHBOARD_LOAD_ACTIONS),
    loadActionsMore: createAction<void>(DASHBOARD_LOAD_ACTIONS_MORE),
    editActionDashboard: createAction(DASHBOARD_EDIT_ACTION, (payload) => {
        return saveAction(payload, actions.editActionDashboard.bind(this, payload))
    }),
    editActionDashboardFullfilled: createAction<void>(DASHBOARD_EDIT_ACTION_FULFILLED),
    actionComplete: createAction(DASHBOARD_COMPLETE_ACTION, (payload) => {
        return actionComplete(payload)
    }),
    actionCompleteFullfilled: createAction<void>(DASHBOARD_COMPLETE_FULFILLED),
    deleteDashboardAction: createAction<void>(DASHBOARD_DELETE_ACTION),
    sendNotification: createAction<void>(DASHBOARD_NOTIFICATION)
}

type DashboardReducerState = {
    dataState: {
        ActionList: any[]
        total_count?: number
    }
    communicationState: {
        isSendingNotification: boolean
    }
}

const initialState: DashboardReducerState = {
    dataState: {
        ActionList: [],
        total_count: 0
    },
    communicationState: {
        isSendingNotification: false
    }
}

const mapActionItem = (item: any) => ({
    id: item.id,
    ...item.attributes,
    notificationStatus: item.attributes?.notificationStatus ?? item.attributes?.notification_status,
    assigned_to: item.attributes?.assignees
        ?.map((assignee: any) => assignee.display_name)
        .join(', '),
    self_manage: item.attributes?.assignees.some((assignee: any) => assignee.self_manage)
})

const actionDashboardReducer: Reducer<DashboardReducerState> = handleActions<DashboardReducerState>(
    {
        [DASHBOARD_RESET_SORT]: (state) => state,

        [DASHBOARD_SORT_ACTIONS]: (state) => state,

        [DASHBOARD_LOAD_ACTIONS]: (state, action) => {
            const { data, meta } = action.payload
            return {
                ...state,
                dataState: {
                    ActionList: data.map(mapActionItem),
                    total_count: meta.total_count
                }
            }
        },
        [DASHBOARD_LOAD_ACTIONS_MORE]: (state, action) => {
            const { data, meta } = action.payload
            return {
                ...state,
                dataState: {
                    ActionList: [...state.dataState.ActionList, ...data.map(mapActionItem)],
                    total_count: meta.total_count
                }
            }
        },
        [DASHBOARD_EDIT_ACTION_FULFILLED]: (state, action) => {
            const saveResult = action.payload.actionItems
            const key = Object.keys(saveResult)[0]
            if (key) {
                const savedActionResult = saveResult[key]
                const savedAction = {
                    ...(state.dataState.ActionList.find((it) => it.id === key) ?? {}),
                    ...savedActionResult.attributes,
                    id: key,
                    notificationStatus:
                        savedActionResult.attributes?.notificationStatus ??
                        savedActionResult.attributes?.notification_status,
                    assigned_to: savedActionResult.attributes?.assignees
                        ?.map((assignee: any) => assignee.display_name)
                        .join(', '),
                    action_completed: savedActionResult.attributes?.completionStatus,
                    due_date: savedActionResult.attributes?.dueDate,
                    completed_date: savedActionResult.attributes?.completedDate
                }

                return {
                    ...state,
                    dataState: {
                        ...state.dataState,
                        ActionList: state.dataState.ActionList.map((action) =>
                            action.id === savedAction.id ? savedAction : action
                        )
                    }
                }
            }
            return state
        },
        [DASHBOARD_COMPLETE_FULFILLED]: (state, action) => {
            const { data } = action.payload
            const savedAction = {
                ...(state.dataState.ActionList.find((it) => it.id === data.id) ?? {}),
                action_completed: data.attributes?.completion_status,
                status: data.attributes?.status,
                completed_date: data.attributes?.completed_date
            }
            delete savedAction['completionStatus']
            return {
                ...state,
                dataState: {
                    ...state.dataState,
                    ActionList: state.dataState.ActionList.map((action) =>
                        action.id === savedAction.id ? savedAction : action
                    )
                }
            }
        },
        [DASHBOARD_DELETE_ACTION]: (state, action) => {
            const id = action.payload
            return {
                ...state,
                dataState: {
                    ActionList: state.dataState.ActionList.filter((it) => it.id !== id)
                }
            }
        },
        [DASHBOARD_NOTIFICATION]: (state, action) => {
            const { id, notification_status } = action.payload
            const savedAction = {
                ...(state.dataState.ActionList.find((it) => it.id === id) ?? {}),
                notification_status: notification_status
            }
            return {
                ...state,
                dataState: {
                    ...state.dataState,
                    ActionList: state.dataState.ActionList.map((action) =>
                        action.id === savedAction.id ? savedAction : action
                    )
                }
            }
        }
    },
    initialState
)

export default actionDashboardReducer
export interface ActionDashboardStateType extends DashboardReducerState {}
