import { dataState, controlState, communicationState } from '../components/helpers/reducerHelpers'
import { createAction, handleActions } from 'redux-actions'
import dataManager from '../businesslayer/minutesDataManager'

import { transientStorageManager } from '../businesslayer/minutesSessionStore'

import validator from '../businesslayer/validation/takerActionValidator'
import { saveAction } from 'businesslayer/api/actions'
import { getContacts } from 'businesslayer/api/contacts'

export const actions = {
    createSectionAction: createAction('CREATE_SECTION_ACTION'),
    editSectionAction: createAction('EDIT_SECTION_ACTION'),
    validateSectionAction: createAction('VALIDATE_SECTION_ACTION'),

    loadContacts: createAction('LOAD_CONTACTS', (payload = false) => {
        return getContacts(payload, actions.loadContacts.bind(this, payload))
    }),

    loadContactsPending: createAction('LOAD_CONTACTS_PENDING'),
    loadContactsFulfilled: createAction('LOAD_CONTACTS_FULFILLED'),
    loadContactsRejected: createAction('LOAD_CONTACTS_REJECTED'),

    prepareSaveAction: createAction('PREPARE_SAVE_ACTION'),

    saveNewAction: createAction('SAVE_NEW_ACTION', (payload) => {
        return saveAction(payload, actions.saveNewAction.bind(this, payload))
    }),

    saveNewActionPending: createAction('SAVE_NEW_ACTION_PENDING'),
    saveNewActionFulfilled: createAction('SAVE_NEW_ACTION_FULFILLED'),
    saveNewActionRejected: createAction('SAVE_NEW_ACTION_REJECTED'),

    saveExistingAction: createAction('SAVE_EXISTING_ACTION', (payload) => {
        return saveAction(payload, actions.saveExistingAction.bind(this, payload))
    }),

    saveExistingActionPending: createAction('SAVE_EXISTING_ACTION_PENDING'),
    saveExistingActionFulfilled: createAction('SAVE_EXISTING_ACTION_FULFILLED'),
    saveExistingActionRejected: createAction('SAVE_EXISTING_ACTION_REJECTED')
}

const initialState = {
    controlState: {
        readyToSave: false
    },
    dataState: {
        currentAction: null,
        savedAction: null,
        section: null,
        minutesId: null,
        allContacts: null,
        dateFormat: null,
        dateInputMask: null
    },
    communicationState: {
        saveComplete: false,
        isSavingExisting: false
    },
    sessionState: {}
}

let updatedState = null
const reducer = handleActions(
    {
        [actions.createSectionAction]: (state, action) => {
            const minutesId = action.payload

            updatedState = dataState(state, {
                dateInputMask: transientStorageManager.dateFormatInput,
                minutesId: minutesId,
                currentAction: dataManager.createDefaultMinuteAction(minutesId)
            })

            return controlState(updatedState, {
                saveComplete: false,
                readyToSave: false,
                savedAction: null
            })
        },

        [actions.editSectionAction]: (state, action) => {
            const sectionAction = action.payload
            updatedState = dataState(state, {
                dateInputMask: transientStorageManager.dateFormatInput,
                minutesId: sectionAction.minutesId,
                currentAction: sectionAction
            })

            return controlState(updatedState, {
                saveComplete: false,
                readyToSave: false,
                savedAction: null
            })
        },

        [actions.validateSectionAction]: (state, action) => {
            const preValidatedAction = action.payload

            const currentAction = validator.validateAction(preValidatedAction)

            return dataState(state, {
                currentAction: Object.assign({}, currentAction)
            })
        },

        [actions.prepareSaveAction]: (state, action) => {
            let currentAction = action.payload
            currentAction = validator.validateAction(currentAction)
            const noErrors =
                !currentAction.validationErrors || currentAction.validationErrors.size === 0

            updatedState = dataState(state, {
                currentAction: Object.assign({}, currentAction)
            })

            if (noErrors) {
                return controlState(updatedState, { readyToSave: true })
            }
            return controlState(updatedState, { readyToSave: false })
        },

        [actions.loadContactsFulfilled]: (state, action) => {
            const allContacts = action.payload.contacts ? action.payload.contacts : null

            //This function is called quite often so we use this function to clean up savedAction
            //so newly opened minutes would not bull this saved action for previously opened minutes
            return dataState(state, {
                allContacts: allContacts,
                dateFormat: transientStorageManager.dateFormat,
                savedAction: null
            })
        },

        [actions.saveNewActionPending]: (state, action) => {
            updatedState = dataState(state, { savedAction: null })
            return communicationState(updatedState, { saveComplete: false })
        },

        [actions.saveNewActionFulfilled]: (state, action) => {
            updatedState = state
            const saveResult = action.payload.actionItems

            const key = Object.keys(saveResult)[0]

            if (key) {
                const savedActionResult = saveResult[key]

                let assignees = savedActionResult.attributes.assignees

                if (assignees) {
                    assignees = assignees.map((c) => {
                        return {
                            email: c.email,
                            id: c.id,
                            text: c.display_name,
                            self_manage: c.self_manage
                        }
                    })
                }

                const savedAction = {
                    id: savedActionResult.id,
                    sortOrder: savedActionResult.attributes.sortOrder,
                    dueDate: savedActionResult.attributes.dueDate,
                    minutesId: savedActionResult.attributes.minutesDocumentId,
                    minutesSectionId: savedActionResult.attributes.minutesSectionId,
                    text: savedActionResult.attributes.text
                        .replace(/^(["]*)/g, '')
                        .replace(/(["]*)$/g, ''),
                    completionStatus: savedActionResult.attributes.completionStatus,
                    notificationStatus: savedActionResult.attributes.notificationStatus,
                    assignees: assignees,
                    createdAt: savedActionResult.attributes.createdAt,
                    updatedAt: savedActionResult.attributes.updatedAt,
                    isNew: true,
                    notes: savedActionResult.attributes.note,
                    status: savedActionResult.attributes.status
                }

                updatedState = dataState(updatedState, { savedAction: savedAction })
            }

            return communicationState(updatedState, { saveComplete: true, readyToSave: false })
        },

        [actions.saveNewActionRejected]: (state, action) => {
            updatedState = dataState(state, { savedAction: null })
            return communicationState(updatedState, { saveComplete: true, readyToSave: false })
        },

        [actions.saveExistingActionPending]: (state, action) => {
            updatedState = dataState(state, { savedAction: null })
            return communicationState(updatedState, { saveComplete: false, isSavingExisting: true })
        },

        [actions.saveExistingActionFulfilled]: (state, action) => {
            updatedState = state
            const saveResult = action.payload.actionItems

            const key = Object.keys(saveResult)[0]

            if (key) {
                const savedActionResult = saveResult[key]

                let assignees = savedActionResult.attributes.assignees

                if (assignees) {
                    assignees = assignees.map((c) => {
                        return {
                            email: c.email,
                            id: c.id,
                            text: c.display_name,
                            self_manage: c.self_manage
                        }
                    })
                }

                //Note: isUpdated flag
                const savedAction = {
                    id: savedActionResult.id,
                    sortOrder: savedActionResult.attributes.sortOrder,
                    dueDate: savedActionResult.attributes.dueDate,
                    minutesId: savedActionResult.attributes.minutesDocumentId,
                    minutesSectionId: savedActionResult.attributes.minutesSectionId,
                    text: savedActionResult.attributes.text
                        .replace(/^(["]*)/g, '')
                        .replace(/(["]*)$/g, ''),
                    completionStatus: savedActionResult.attributes.completionStatus,
                    notificationStatus: savedActionResult.attributes.notificationStatus,
                    assignees: assignees,
                    isUpdated: true,
                    createdAt: savedActionResult.attributes.createdAt,
                    updatedAt: savedActionResult.attributes.updatedAt,
                    notes: savedActionResult.attributes.note,
                    completed_date: savedActionResult.attributes.completedDate,
                    status: savedActionResult.attributes.status
                }

                updatedState = dataState(updatedState, { savedAction: savedAction })
            }

            return communicationState(updatedState, {
                saveComplete: true,
                readyToSave: false,
                isSavingExisting: false
            })
        },

        [actions.saveExistingActionRejected]: (state, action) => {
            updatedState = dataState(state, { savedAction: null })
            return communicationState(updatedState, {
                saveComplete: true,
                readyToSave: false,
                isSavingExisting: false
            })
        }
    },
    initialState
)

export default reducer
