import React, { useState, useMemo, useCallback, useEffect } from 'react'
import 'react-hint/css/index.css'
import { GetSettings } from 'components/requests/GetSettingsRequest'
import { pathOr } from 'rambdax'
import { AppProviders } from 'AppProviders'
import Routes from 'routes'
import { AppLock } from 'AppLock'
import { StoreUrlParams } from 'StoreUrlParams'
import { AuthLock } from 'authentication-lock'
import { BoardsIOSBridgeProvider } from 'components/contexts/boards-ios/js-bridge'
import {
    getLocalStorageItem,
    getSessionStorageItem,
    removeSessionStorageItem,
    removeLocalStorageItem,
    setLocalStorageItem,
    setSessionStorageItem
} from 'businesslayer/minutesLocalStore'
import LinearIndeterminate from 'components/shared/LinearProgress'
import { doLogin } from 'businesslayer/sso/SsoManagerFactoryService'
import { getSearchParameter } from 'components/helpers/DomHelper'
import Cookies from 'js-cookie'

const Loading = ({ isLoading }) => <LinearIndeterminate loading={isLoading} />

const isBoardLogin = () => {
    const platform = getSessionStorageItem('parentPlatform')
    return platform === 'boardswebadmin' || platform === 'boardswebdirector'
}

const isOidcEnabledBoard = () => {
    const { _env_ } = window
    return _env_.ENABLE_OIDC_LOGIN_BOARD
}
const getUrlPath = (): string => {
    const url = window.location.href
    const urlObj = new URL(url)
    return urlObj.pathname
}

const isBoardEffect = (platform: any) => {
    if (process.env.NODE_ENV === 'development') {
        return true
    }
    if (platform !== 'boardswebadmin' && platform !== 'boardswebdirector') {
        let initialAuthToken = Cookies.get('authToken')
        if (initialAuthToken) {
            setSessionStorageItem('forbidden', 'false')
            return true
        } else {
            return false
        }
    }
    return false
}

const clearSessions = () => {
    removeSessionStorageItem('committeeId')
    removeSessionStorageItem('committeeName')
    removeSessionStorageItem('siteName')
    removeSessionStorageItem('bookId')
    removeSessionStorageItem('parentPlatform')
    removeSessionStorageItem('sessionId')
    removeSessionStorageItem('landingPath')
    removeSessionStorageItem('uuid')
    removeSessionStorageItem('isNotification')
}

const App = () => {
    const [isLoading, setIsLoading] = useState(true)
    const authtoken = getSearchParameter(window.location, ['authToken', 'authtoken'])
    const [isEnvAndTokenMatch, setIsEnvAndTokenMatch] = useState(false)
    const uuid = sessionStorage.getItem('uuid')
    const sessionId = getLocalStorageItem('sessionId')
    if (process.env.NODE_ENV === 'development' && !sessionId) {
        doLogin()
    }
    useEffect(() => {
        let url = new URL(window.location.href)
        const sessionId = getLocalStorageItem('sessionId')

        if (sessionId) {
            const env = sessionId.split(':')[0].toLowerCase()
            const match = url.origin.toLowerCase().includes(env)
            setIsEnvAndTokenMatch(match)
        }
    }, [isEnvAndTokenMatch])
    if (authtoken) {
        setSessionStorageItem('authToken', 'true')
        clearSessions()
    }
    // Handle site switch
    let url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    if (params.get('token') === 'environmentRedirect') {
        console.log('The URL contains token=environmentRedirect, we can performe OIDC')
        const userLanguage = getSearchParameter(window.location, ['userLanguage', 'userLanguage'])
        if (userLanguage !== null) {
            setLocalStorageItem('userLanguage', userLanguage)
        }
        const platform = getSearchParameter(window.location, ['parentPlatform', 'parentplatform'])
        if (platform !== null) {
            setSessionStorageItem('parentPlatform', platform)
        }
        setLocalStorageItem('landingPath', '/')
        if (!uuid) {
            removeSessionStorageItem('sessionId')
            removeLocalStorageItem('sessionId')
        }
        doLogin()
    } else {
        console.log('The URL does not contain the expected token, lets do the default behavior')
    }
    // Changes for OIDC flow
    const platform = getSearchParameter(window.location, ['parentPlatform', 'parentplatform'])
    const boardEffectPlatform = isBoardEffect(platform)
    const isBoardsIOS = platform === 'boardsios'
    if (!isBoardEffect(platform)) {
        if (!!platform) {
            setSessionStorageItem('parentPlatform', platform)
        }
    }

    if (isBoardLogin()) {
        const bookId = getSearchParameter(window.location, ['bookid', 'bookId'])
        if (bookId !== null) {
            setSessionStorageItem('bookId', bookId)
        }

        const urlCommitteeId = getSearchParameter(window.location, ['committeeId', 'committeeid'])
        if (urlCommitteeId !== null) {
            setSessionStorageItem('committeeId', urlCommitteeId)
        }

        const committeeName = getSearchParameter(window.location, [
            'committeeName',
            'committeename'
        ])
        if (committeeName !== null) {
            setSessionStorageItem('committeeName', committeeName)
        }

        const siteName = getSearchParameter(window.location, ['siteName', 'sitename'])
        if (siteName !== null) {
            setSessionStorageItem('siteName', siteName)
        }

        setLocalStorageItem('landingPath', getUrlPath())
        const userLanguage = getSearchParameter(window.location, ['userLanguage', 'userLanguage'])
        if (userLanguage !== null) {
            setLocalStorageItem('userLanguage', userLanguage)
        }
    } else {
        setSessionStorageItem('environmentRedirect', 'true')
    }

    const isOidcEnabled = isOidcEnabledBoard() === true || isOidcEnabledBoard() === 'true'
    const isLoginRequired = isBoardLogin() && isOidcEnabled

    // Only applicable for BoardsWebAdmin and BoardsWebDirector while launching from BoardsWeb
    if (!isBoardsIOS && authtoken && isLoginRequired) {
        if (!uuid) {
            removeSessionStorageItem('sessionId')
            removeLocalStorageItem('sessionId')
        }
        doLogin()
    }

    if (!isBoardsIOS && isLoginRequired && (!sessionId || sessionId === '')) {
        if (!uuid) {
            removeSessionStorageItem('sessionId')
            removeLocalStorageItem('sessionId')
        }
        doLogin()
    }

    // update landing path
    const sessionPlatform = useMemo(() => getSessionStorageItem('parentPlatform'), [])
    const handleLoadSuccess = useCallback(
        () =>
            setTimeout(() => {
                setIsLoading(false)
            }, 1000),
        []
    )
    const forbidden = getSessionStorageItem('forbidden')
    const isForbidden = forbidden === 'true'

    return (
        <>
            {/* if isOidcEnabled is false then existing flow (authtoken) will work && isLoginRequired */}
            {authtoken && isLoginRequired ? null : (
                <StoreUrlParams>
                    {({ committeeId, authTokens, platform, uuid, siteName }) => (
                        <AppContent
                            committeeId={committeeId}
                            authTokens={authTokens}
                            platform={platform}
                            uuid={uuid}
                            siteName={siteName}
                            sessionPlatform={sessionPlatform}
                            handleLoadSuccess={handleLoadSuccess}
                            isEnvAndTokenMatch={isEnvAndTokenMatch}
                            forbidden={isForbidden}
                            boardEffectPlatform={boardEffectPlatform}
                        />
                    )}
                </StoreUrlParams>
            )}
            <Loading isLoading={isLoading} />
        </>
    )
}

// Component that handles the core app content
const AppContent = ({
    committeeId,
    authTokens,
    platform,
    uuid,
    siteName,
    sessionPlatform,
    handleLoadSuccess,

    isEnvAndTokenMatch,
    forbidden,
    boardEffectPlatform
}) => (
    <BoardsIOSBridgeProvider platform={platform || sessionPlatform}>
        <AuthLock
            platform={platform || sessionPlatform}
            uuid={uuid}
            authTokens={authTokens}
            siteName={siteName}>
            {((!forbidden && isEnvAndTokenMatch) || boardEffectPlatform) && (
                <GetSettings>
                    {({ settings }) => (
                        <AppProviders
                            platform={settings.platform}
                            language={pathOr('en-us', ['userLanguage'], settings)}
                            committeeId={committeeId || ''}>
                            <AppLock
                                platform={settings.platform}
                                onLoadedSuccessful={handleLoadSuccess}>
                                <Routes />
                            </AppLock>
                        </AppProviders>
                    )}
                </GetSettings>
            )}
        </AuthLock>
    </BoardsIOSBridgeProvider>
)

// Fullscreen loading spinner

export default App
