import PopoverButton from 'components/shared/PopoverButton'
import React, { FC, useState } from 'react'
import { translate } from 'components/shared/internationalization'
import { FlatButton } from '../StyledComponents'
import { ExportPopover, WhosIsUsingMeType } from './ExportPopover'
import { ColorBaseSkyBlue, ColorBaseWhite, ColorEastBay } from 'assets/styles/variables'
import { exportMinutes } from 'businesslayer/api/minutes'
import { exportActions } from 'businesslayer/api/actions'
import { saveFile } from 'common/util/file'
import { useDialog } from 'components/shared/context/dialog'
import { getSessionStorageItem } from 'businesslayer/minutesLocalStore'
import i18n from 'i18next'

import { makeStyles } from '@material-ui/core'

type Props = {
    children?: any
    className?: string
    isDisabled?: boolean
    minutesItem: MinutesRaw | Minutes
    actionSortField: ActionListSort
    isIconOnlyMode?: boolean
    whoIsUsingMe?: WhosIsUsingMeType
}

const ExportPopoverButton: FC<Props> = ({
    children,
    isDisabled,
    minutesItem,
    actionSortField,
    whoIsUsingMe
}) => {
    const useStyles = makeStyles(() => ({
        exportPopoverButton: {
            fontFamily: 'Source Sans Pro',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '20px',
            marginLeft: '12px',
            height: '40px',
            color: ColorEastBay,
            backgroundColor: ColorBaseWhite,
            border: `1px solid ${ColorEastBay}`,
            padding: '8px 12px 8px 12px',
            '&:focus, &:active': {
                height: '40px',
                boxShadow: `0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px ${ColorBaseSkyBlue}`,
                borderRadius: '2px'
            },
            '&:hover': {
                height: '40px',
                color: ColorEastBay,
                backgroundColor: '#94949429'
            }
        }
    }))
    const classes = useStyles()
    const [isSaving, setIsSaving] = useState(false)

    const dialog = useDialog('ExportActionsDialog')

    const handleDownloadFile = ({ response, responseType }) => {
        setIsSaving(true)
        saveFile({ response, responseType })
        setIsSaving(false)
    }

    const makeHandleExportMinutes = (minutesId, minutesOnly = false) => async () => {
        try {
            const committeeId = getSessionStorageItem('currentCommitteeId')
            const response = await exportMinutes(
                committeeId,
                minutesId,
                minutesOnly,
                exportMinutes.bind(null, committeeId, minutesId, committeeId, minutesOnly)
            )
            handleDownloadFile({ response, responseType: 'docx' })
        } catch (error) {}
    }

    const makeHandleExportActions = (minutesId, sortField) => async () => {
        try {
            const committeeId = getSessionStorageItem('currentCommitteeId')
            const response = await exportActions(
                committeeId,
                minutesId,
                sortField,
                exportActions.bind(null, committeeId, minutesId, sortField)
            )
            handleDownloadFile({ response, responseType: 'xlsx' })
        } catch (error) {}
    }

    const handleConsolidatedActions = () => {
        !!dialog.showDialog && dialog.showDialog('ExportActionsDialog')
    }

    return (
        <PopoverButton>
            {({ onShowPopover, isOpen, onClosePopover, anchorElement, closeReason }) => {
                const button = (
                    <FlatButton
                        onClick={onShowPopover}
                        className={classes.exportPopoverButton}
                        disableFocusRipple
                        disableRipple
                        aria-label={i18n.t('EXPORT_MINUTES')}
                        data-analytics="MM-Export"
                        disabled={isDisabled || isSaving}>
                        {translate('EXPORT')}
                    </FlatButton>
                )
                return (
                    <React.Fragment>
                        {children ? children({ onShowPopover }) : button}
                        <ExportPopover
                            onClosePopover={onClosePopover}
                            isOpen={isOpen}
                            anchorElement={anchorElement}
                            closeReason={closeReason}
                            minutesItem={minutesItem}
                            whoIsUsingMe={whoIsUsingMe}
                            onExport={makeHandleExportMinutes(minutesItem.id)}
                            onExportMinutesOnly={makeHandleExportMinutes(minutesItem.id, true)}
                            onExportActionsOnly={makeHandleExportActions(
                                minutesItem.id,
                                actionSortField
                            )}
                            onConsolidatedActions={handleConsolidatedActions}
                        />
                    </React.Fragment>
                )
            }}
        </PopoverButton>
    )
}
export default ExportPopoverButton
