import React, { FC } from 'react'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import { withStyles } from '@material-ui/core/styles'
import { chipInputStyles, atlasChipInputStyles } from 'components/chip-input/chip-input-styles'
import {
    chipInputComponents,
    AtlasMultiValue,
    OldMultiValue
} from 'components/chip-input/chip-input-components'
import { ChipInputFieldProps, MakeOnChipChangeProps, OnChipChangeProps } from './types'

const selectStyles = {
    container: (base: any) => ({
        ...base,
        width: '100%'
    }),
    input: (base: any) => ({
        ...base,
        '& input': {
            font: 'inherit',
            height: '25px'
        }
    })
}

const ChipInputField: FC<ChipInputFieldProps> = ({
    components: propComponents,
    floatingLabelText,
    error,
    helperText,
    disableCreate,
    isDisabled,
    isAtlas = false,
    variant,
    ...rest
}) => {
    const fieldError = error === undefined ? !!helperText : error
    const MultiValueChip = !!isAtlas ? AtlasMultiValue : OldMultiValue
    const components = {
        ...chipInputComponents,
        MultiValue: MultiValueChip
    }
    const allProps: any = {
        styles: selectStyles,
        components: { ...components, ...propComponents },
        textFieldProps: {
            label: !!floatingLabelText ? floatingLabelText : '',
            InputLabelProps: {
                shrink: variant === 'outlined' ? false : true
            },
            helperText: helperText,
            error: fieldError,
            variant
        },
        getOptionLabel: (option) => {
            return option.text
        },
        getNewOptionData: (name) => {
            return { text: name, value: name }
        },
        isMulti: true,
        defaultMenuIsOpen: false,
        openMenuOnFocus: false,
        openMenuOnClick: false,
        deleteRemoves: true,
        required: true,
        placeholder: '',
        isAtlas,
        isDisabled,
        ...rest
    }
    return disableCreate ? (
        <Select
            {...allProps}
            components={{ ...allProps.components, NoOptionsMessage: () => null }}
        />
    ) : (
        <CreatableSelect
            {...allProps}
            menuPosition={'fixed'}
            menuShouldBlockScroll={true}
            menuPortalTarget={
                !!floatingLabelText ? null : document.getElementById('ChipInputFieldContainer')
            }
        />
    )
}

// #region Helper Functions
export const makeOnChipChange = <T extends unknown>({
    addChip,
    deleteChip
}: MakeOnChipChangeProps<T>) => ({ options, action }: OnChipChangeProps<T>) => {
    if (action.action === 'select-option') {
        return addChip(action.option, options)
    } else if (action.action === 'create-option') {
        return addChip(options[options.length - 1], options)
    } else if (['remove-value', 'pop-value'].includes(action.action)) {
        return deleteChip(action.removedValue, options)
    }
    return undefined
}

export const makeOnBlurAddUnique = (addChip) => ({ event, currentData }) => {
    if (!!event.target.value) {
        const isUnique = currentData.every((chip) => {
            return event.target.value.toLowerCase() !== chip.text.toLowerCase()
        })
        if (isUnique) {
            const newObject = {
                text: event.target.value,
                value: event.target.value
            }
            addChip(newObject)
        }
    }
}
// #endregion

export const AtlasChipInputField = withStyles(atlasChipInputStyles)(ChipInputField)
export default withStyles(chipInputStyles)(ChipInputField)
