/** @jsx jsx */
import { FC } from 'react'
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/react'
import {
    AtlasPrimary,
    ColorBaseMulberryBlue,
    ColorBaseSkyBlue,
    ColorEastBay
} from 'assets/styles/variables'
import { translate } from 'components/shared/internationalization'
import i18n from 'i18next'
import MinuteTakerHeader from 'components/minutetaker/MinuteTakerHeader'

/**
 * Provides Components for overall page layout
 */

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    diligentHelpButton: {
        backgroundColor: `${ColorEastBay}`,
        lineHeight: '3.2rem',
        width: '2rem',
        minWidth: 'unset',
        height: '2rem',
        borderRadius: '2px',
        padding: '0',
        color: 'white',
        position: 'relative',
        cursor: 'pointer',
        marginRight: '1rem',
        '&:focus, &:active': {
            outline: `2px solid ${ColorBaseSkyBlue}`
        }
    },
    line1: {
        transform: 'rotateX(-70deg) rotateY(0) rotate(0)',
        animation: '$line1Animation 12s cubic-bezier(0.83, 0.82, 0.82, 0.99) infinite'
    },
    line2: {
        transform: 'rotateX(-40deg) rotateY(60deg) rotate(-44deg)',
        animation: '$line2Animation 12s cubic-bezier(0.83, 0.82, 0.82, 0.99) infinite'
    },
    line3: {
        transform: 'rotateX(50deg) rotateY(50deg) rotate(180deg)',
        animation: '$line3Animation 12s cubic-bezier(0.83, 0.82, 0.82, 0.99) infinite'
    },
    line: {
        borderRadius: '50%',
        border: '3px solid white',
        borderBottomColor: 'transparent',
        width: '21px',
        height: '21px',
        position: 'absolute',
        top: '4px',
        left: '5px'
    },
    '@keyframes line1Animation': {
        '0%': {
            transform: 'rotateX(-70deg) rotateY(0deg) rotateZ(0deg)'
        },
        '15%, 100%': {
            transform: 'rotateX(-70deg) rotateY(0deg) rotateZ(1080deg)'
        }
    },
    '@keyframes line2Animation': {
        '0%': {
            transform: 'rotateX(-40deg) rotateY(60deg) rotateZ(-44deg)'
        },
        '15%, 100%': {
            transform: 'rotateX(-40deg) rotateY(60deg) rotateZ(676deg)'
        }
    },
    '@keyframes line3Animation': {
        '0%': {
            transform: 'rotateX(50deg) rotateY(50deg) rotateZ(180deg)'
        },
        '15%, 100%': {
            transform: 'rotateX(50deg) rotateY(50deg) rotateZ(1620deg)'
        }
    }
}))

export default useStyles

export const PageLayout = ({ children }) => {
    return <Layout>{children}</Layout>
}

type PageHeaderProps = {
    title: string | JSX.Element
    onSignout: (...args: any) => void
}

export const PageHeader: FC<PageHeaderProps> = ({ children }) => {
    return <Header>{children}</Header>
}

export const MinutesManagerToolbar = () => {
    return <MinuteTakerHeader title={i18n.t('TOOLTIP_MINUTES')} />
}

export const PageBody = ({ children }) => (
    <Body>
        <ContentContainer>{children}</ContentContainer>
    </Body>
)
export const PageFooter = ({ onAboutClick }) => (
    <Footer role="contentinfo">
        <AboutLink
            className="text-xsmall-white"
            data-testid="MinutesManager_About_Btn"
            data-analytics="MM-AboutLink"
            onClick={onAboutClick}
            onTouchEnd={onAboutClick}>
            {translate('ABOUT_MINUTES_LINK')}
        </AboutLink>
    </Footer>
)

export const atlasNoncompliant = css`
    background-color: #f0f1;
`

// Layout Constants
const CONTENT_MARGIN = 30
const CONTENT_MIN_WIDTH = 700
const CONTENT_VERTICAL_OFFSET = 16
const FOOTER_HEIGHT = 24
const PAGE_MIN_WIDTH = CONTENT_MIN_WIDTH + CONTENT_MARGIN * 2

const Layout = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    min-width: ${PAGE_MIN_WIDTH}px;
    max-width: max(${PAGE_MIN_WIDTH}px, 100vw);
    height: 100%;
    overflow-y: hidden;
    width: 100vw;
    }
`
export const Header = styled('div')`
    width: auto;
`

const Body = styled('div')`
    justify-content: center;
    align-items: flex-start;
    padding-top: ${CONTENT_VERTICAL_OFFSET}px;
    padding-left: ${CONTENT_MARGIN}px;
    padding-right: ${CONTENT_MARGIN}px;
    overflow: hidden;
    padding: 0px 24px;
    padding-top: '8px';
    overflow-y: auto;
    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 0;
    }
`
const ContentContainer = styled('div')`
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    width: 100%;
    min-width: ${CONTENT_MIN_WIDTH}px;
    background-color: white;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 300px);
    @media (min-width: 768px) and (max-width: 1180px) {
        max-height: calc(100vh - 350px);
    }
    z-index: 0;
    & > div > div:focus {
        border: 2px solid ${ColorBaseMulberryBlue};
    }

    & > div > div > div :focus {
        box-shadow: 0px 0px 0px 1px #ffffff, 0px 0px 0px 3px ${ColorBaseMulberryBlue};
    }

    /* Scroll properties */
    &::-webkit-scrollbar {
        width: 6px;
        height: 716.8px;
    }

    &::-webkit-scrollbar-thumb {
        background: #acb1b8;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #acb1b8;
    }
`
const Footer = styled.footer`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: ${FOOTER_HEIGHT}px;
    min-width: ${PAGE_MIN_WIDTH}px;
    background-color: ${AtlasPrimary};
`
const AboutLink = styled('div')`
    line-height: 100%;
    padding-right: 20px;
    cursor: pointer;

    /* Increase Touch Target for iPad */
    padding-top: 20px;
    margin-top: -20px;
    padding-left: 20px;
    margin-left: -20px;
`
// Allows child div of slot to overflow based on parents flex width amount
// https://css-tricks.com/flexbox-truncated-text/
// const flexFill = css`
//     min-width: 0;
// `

export const MinutesManager_BtnFocusCss = css`
    &:focus,
    &:active {
        outline: none;
        box-shadow: 0px 0px 0px 1px #ffffff, 0px 0px 0px 3px ${ColorBaseSkyBlue};
        border-radius: 2px;
    }
`
export const attendanceReport_BtnCss = css`
    display: flex;
    padding: 8px 12px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 2px;
    background: none;
    color: white;
    margin-top: 7px;
    margin-left: 1rem;
    border: 1px solid var(--semantic-color-type-inverse, #fff);
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`
export const SideBarFocusCss = css`
    padding: 12px;
    position: sticky;
    &:focus {
        box-shadow: 0px 0px 0px 1px #ffffff, 0px 0px 0px 3px #3e95fa;
    }
`
