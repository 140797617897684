import i18n from 'i18next'
import { AtlasSelect, PLACEHOLDER_KEY } from 'components/atlas-material/select'
import { MenuItem, Tooltip, makeStyles } from '@material-ui/core'
import styled from '@emotion/styled'
import { withFont } from 'assets/sharedStyles'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import React, { useCallback, useMemo } from 'react'
import { makeMuiFieldAdapter } from 'components/final-form/final-form-helper'
import { ClassNameMap } from '@material-ui/styles'

type Props = {
    label?: string
    isReadonly: boolean
    error?: boolean
    helperText?: string
    value?: Committee | undefined
    committees: Committee[]
    onChange: (c: Committee) => void
    onBlur?: (event: any) => void
    required?: boolean
}
const useStyles = makeStyles(() => ({
    container: { maxWidth: '400px', overflow: 'hidden', textOverflow: 'ellipsis' },
    wrapper: {
        width: '99%'
    }
}))
export const CommitteeSelect: React.FC<Props> = ({
    label,
    isReadonly,
    value = { id: PLACEHOLDER_KEY, name: '' },
    onChange,
    committees,
    error,
    helperText,
    onBlur,
    required
}) => {
    const classes = useStyles()
    const platform = transientStorageManager.platform as Platform
    const placeholder =
        platform === 'boardeffect'
            ? i18n.t('SELECT_COMMITTEE_PLACEHOLDER.BE.required')
            : i18n.t('SELECT_COMMITTEE_PLACEHOLDER.BB.required')
    const defaultLabel = platform === 'boardeffect' ? i18n.t('WORKROOM') : i18n.t('COMMITTEE')

    const handleCommitteeClick = useCallback(
        (event) => {
            const committeeId = event.target.value
            let committee = committees.find((c) => +c.id === +committeeId)
            !!committee && onChange(committee)
        },
        [onChange, committees]
    )
    const valueOrPlaceholder = !!value && !!value.id ? value.id : PLACEHOLDER_KEY
    const menuItems = useMemo(() => getMenuItems(committees, classes), [committees, classes])

    return isReadonly ? (
        <ReadOnlyCommittee>{`${label || defaultLabel}: ${value.name}`}</ReadOnlyCommittee>
    ) : (
        <AtlasSelect
            label={label ? label : i18n.t('COMMITTEE_REQUIRED')}
            data-testid="MinutesProperties_CommitteeSelect"
            role="combobox"
            data-analytics="MM-Filter-Committee"
            id="committee"
            className={classes.wrapper}
            ariaLabelledby="committee"
            value={valueOrPlaceholder}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
            onChange={handleCommitteeClick}
            onBlur={onBlur}
            required={required}>
            {menuItems}
        </AtlasSelect>
    )
}

const getMenuItems = (committees: Committee[], classes: ClassNameMap<'container'>) =>
    committees.map((committee) => {
        const shouldShowTooltip = committee.name.length > 50
        return (
            <MenuItem
                key={committee.name}
                value={committee.id}
                className={`dropDownFocus ${shouldShowTooltip ? 'tooltip' : ''}`}>
                <Tooltip
                    title={shouldShowTooltip ? committee.name : ''}
                    placement="top"
                    key={committee.id}>
                    <p className={classes.container}>{committee.name}</p>
                </Tooltip>
            </MenuItem>
        )
    })

const ReadOnlyCommittee = withFont('text-body-grey')(styled.div`
    display: flex;
    align-items: center;
    height: 50px;
`)

export const CommitteeSelectAdapter = makeMuiFieldAdapter<Props>(CommitteeSelect)
