import * as MM from '../Types'

import { dataState, communicationState } from '../components/helpers/reducerHelpers'
import { createAction, handleActions } from 'redux-actions'
import { Reducer } from 'redux'

import { transientStorageManager } from '../businesslayer/minutesSessionStore'
import { getSessionStorageItem } from 'businesslayer/minutesLocalStore'
import validator from '../businesslayer/validation/notificationValidator'

import i18n from 'i18next'
import { sendNotification } from 'businesslayer/api/actions'

type NotificationPayload = {
    platform: string | null
    to: string | null
    subject: string
    body: string | null
    minutesId: string | null
    actionId: string | null
    validationErrors: string[] | null
    invalidEmails: string[] | null
}
type ActionsType = {
    sortActions: any // Change 'any' to the appropriate return type if you expect a payload
    resetSort: any
    initNotification: any
    resetNotification: any
    validateNotification: any

    sendNotification: any
    sendNotificationPending: string
    sendNotificationFulfilled: string
    sendNotificationRejected: string
}
export const actions: ActionsType = {
    sortActions: createAction<void>('SORT_ACTIONS'),
    resetSort: createAction<void>('RESET_SORT'),
    initNotification: createAction<NotificationPayload>('INIT_NOTIFICATION'),
    resetNotification: createAction<void>('RESET_NOTIFICATION'),
    validateNotification: createAction<NotificationPayload>('VALIDATE_NOTIFICATION'),

    sendNotification: createAction<NotificationPayload>('SEND_NOTIFICATION', (payload) =>
        sendNotification(payload, actions.sendNotification.bind(null, payload))
    ),

    sendNotificationPending: createAction<void>('SEND_NOTIFICATION_PENDING'),
    sendNotificationFulfilled: createAction<void>('SEND_NOTIFICATION_FULFILLED'),
    sendNotificationRejected: createAction<void>('SEND_NOTIFICATION_REJECTED')
}

type ReducerState = {
    dataState: {
        sortField: MM.ActionListSort
        platform: string | null
        siteName: string | null
        notification: MM.ActionNotification | null
        notificationAction: any | null
        dateFormat: string | null
        savedAction: any | null
    }
    communicationState: {
        isSendingNotification: boolean
    }
}

const initialState: ReducerState = {
    dataState: {
        sortField: { name: 'section', ascending: true },
        platform: null,
        siteName: null,
        notification: null,
        notificationAction: null,
        dateFormat: null,
        savedAction: null
    },
    communicationState: {
        isSendingNotification: false
    }
}

let updatedState = null
function resetNotificationState(state: ReducerState) {
    return dataState(state, {
        notification: null,
        notificationAction: null,
        savedAction: null
    })
}

function updateSendingNotificationState(state: ReducerState, isSending: boolean) {
    const updatedState = communicationState(state, { isSendingNotification: isSending })
    return dataState(updatedState, { savedAction: null })
}

const reducer: Reducer<ReducerState> = handleActions<ReducerState>(
    {
        [actions.resetSort]: (state: ReducerState) =>
            dataState(state, { sortField: { name: 'section', ascending: true } }),

        [actions.sortActions]: (state: ReducerState, action) =>
            dataState(state, { sortField: action.payload }),

        [actions.resetNotification]: resetNotificationState,

        [actions.validateNotification]: (state: ReducerState, action) =>
            dataState(state, { notification: validator.validateNotification(action.payload) }),

        [actions.initNotification]: (state: ReducerState, action) => {
            const siteName = getSessionStorageItem('siteName') || transientStorageManager.siteName
            const platform = transientStorageManager.platform
            const subject = i18n.t('EMAIL_SUBJECT')
            const notification: MM.ActionNotification = {
                platform: null,
                to: null,
                subject,
                body: null,
                minutesId: null,
                actionId: null,
                validationErrors: null,
                invalidEmails: null
            }

            const dateFormat = transientStorageManager.dateFormat

            updatedState = dataState(state, {
                platform,
                siteName,
                notificationAction: action.payload,
                notification,
                dateFormat,
                savedAction: null
            })

            return updatedState
        },

        [actions.sendNotificationPending]: (state: ReducerState) =>
            updateSendingNotificationState(state, true),

        [actions.sendNotificationFulfilled]: (state: ReducerState) => {
            return updateSendingNotificationState(state, false)
        },

        [actions.sendNotificationRejected]: (state: ReducerState) =>
            updateSendingNotificationState(state, false)
    },
    initialState
)

export default reducer
export interface MinuteActionItemsStateType extends ReducerState {}
