import React, { FC, useCallback, useMemo } from 'react'
import { Form, Field, FormSpy } from 'react-final-form'
import { FormBody } from 'components/layout/modal-form-layout'
import { RadioButton, FormControlLabel, Divider } from 'components/shared/StyledComponents'
import RadioButtonGroupField from 'components/minutetaker/components/form/RadioButtonGroupField'

import { AtlasDatePickerAdapter } from 'components/date-time-pickers/date-picker'
import { LabelCss } from 'components/meeting-date-time-control/meeting-date-fields'
import { css } from '@emotion/css'
import { MuiThemeProvider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { AtlasPrimary, AtlasStatusError } from 'material-atlas/palette'

import DifferentDueDate from './DifferentDueDate'
import i18n from 'common/util/internationalization'
import { getAtlasHelperText } from 'components/text-field/input-error-text'
import moment from 'moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { materialAtlasTheme } from 'material-atlas/theme'
import { DATE_STEPS, INVITEE_CONSTANTS } from '../../types'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import { formSpySameDueDateOnChange } from '../../util'

const useStyles = makeStyles(() => ({
    root: {
        height: '40px'
    },

    radioBtnCss: {
        '&$root': {
            color: '#000000'
        },
        '&$checked': {
            color: `${AtlasPrimary} !important`
        }
    },

    dueDateTable: {
        maxHeight: '300px',
        overflowY: 'auto',
        overflow: 'hidden',
        paddingBottom: '16px'
    },
    tableCaption: {
        textAlign: 'left',
        fontSize: '13px',
        marginBottom: '1.5rem',
        color: '#000000'
    },

    checked: {},
    generalHeaderTag: {
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left',
        paddingTop: '10px',
        marginTop: 'auto',
        marginBottom: 'auto',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        color: 'var(--semantic-color-action-active, #252C44)'
    },
    tableStyle: {
        textAlign: 'justify',
        marginLeft: '2.5rem',
        marginBottom: '24px'
    },
    radioStyle: {
        marginLeft: '2.5rem'
    },
    reviewerDiscription: {
        color: '#000',
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '16px',
        marginBottom: '24px'
    },
    label: {
        '& *': { color: '#000000' },
        textWrap: 'nowrap'
    },
    divider: {
        backgroundColor: '#e6e6e6'
    }
}))

type Props = {
    initialValues?: InitialValue
    recepients: PersonChip[]
    onHandleSubmitForm: (values: DueDateFormValues) => void
    stepTwoError: DueDateError
    setStepTwoError: React.Dispatch<React.SetStateAction<DueDateError>>
}
const DueDateForm: FC<Props> = ({
    initialValues,
    stepTwoError,
    setStepTwoError,
    recepients,
    onHandleSubmitForm
}) => {
    const classes = useStyles()
    const { dateFormatInput } = transientStorageManager
    const sameDate = useMemo(() => moment(recepients[0].due_date), [recepients])

    const radioBtnArray = useCallback(
        (key: string, label: string, dataAnalytics?: string) => (
            <FormControlLabel
                name={key}
                key={key}
                value={key}
                className={classes.label}
                control={
                    <RadioButton
                        disableFocusRipple
                        classes={{
                            root: classes.radioBtnCss,
                            checked: classes.checked
                        }}
                        onKeyDown={(event) => {
                            if (event.key === ' ' || event.key === 'Enter') {
                                event.preventDefault()
                                event.currentTarget.click()
                            }
                        }}
                    />
                }
                label={label}
                data-analytics={dataAnalytics}
            />
        ),
        [classes]
    )
    const sameDatePicker = useCallback(
        (input, { ...props }) => (
            <div>
                <AtlasDatePickerAdapter
                    value={input.value}
                    onChange={input.onChange}
                    input={input}
                    color="primary"
                    inputProps={{
                        'aria-label': 'checkbox'
                    }}
                    {...props}
                />
            </div>
        ),

        []
    )
    const differentDueDateComponent = useCallback(
        (values, form) =>
            values?.recipients?.length > 0 &&
            values.recipients.map((val, key) => (
                <DifferentDueDate
                    key={val.id}
                    val={val}
                    k={key}
                    form={form}
                    stepTwoError={stepTwoError}
                    setStepTwoError={setStepTwoError}
                />
            )),

        [setStepTwoError, stepTwoError]
    )
    const memoisedRadioBtn = useCallback(
        (values, form) => {
            return [
                radioBtnArray(DATE_STEPS.NO_DUE_DATE, i18n.t('NO_DUE_DATE_LABEL'), 'MM-NoDueDate'),
                radioBtnArray(
                    DATE_STEPS.SAME_DUE_DATE,
                    i18n.t('SAME_DUE_DATE_LABEL'),
                    'MM-SameDueDate'
                ),
                <div className={classes.radioStyle} key="same_due_date_field">
                    {values?.due_date_fields === INVITEE_CONSTANTS.SAME_DUE_DATE && (
                        <div>
                            <Field
                                InputLabelProps={{
                                    style: { color: 'inherit' }
                                }}
                                name="sameDueDate"
                                label={i18n.t('DUE_DATE_LABEL.default')}
                                className={css`
                                    ${LabelCss}
                                    fieldset {
                                        border-color: ${stepTwoError?.sameDueDate &&
                                        AtlasStatusError} !important;
                                    }
                                    margin-bottom: 8px;
                                `}
                                style={{ width: 'auto' }}
                                value={initialValues?.recipients[0].due_date}
                                data-testid="MinutesProperties_MeetingDatePicker"
                                defaultValue={
                                    initialValues?.due_date_fields ===
                                    INVITEE_CONSTANTS.SAME_DUE_DATE
                                        ? sameDate
                                        : INVITEE_CONSTANTS.EMPTY
                                }
                                render={({ input, value, form, ...props }) =>
                                    sameDatePicker(input, { ...props })
                                }
                                helperText={null}
                                showErrorWhen={true}
                            />
                            {getAtlasHelperText({
                                errorText: stepTwoError?.sameDueDate
                            })}
                        </div>
                    )}
                </div>,
                radioBtnArray(
                    DATE_STEPS.DIFFERENT_DUE_DATE,
                    i18n.t('DIFFERENT_DUE_DATE_LABEL'),
                    'MM-DifferentDueDate'
                ),

                <div key="different_due_date_field" className={classes.dueDateTable}>
                    {values?.due_date_fields === INVITEE_CONSTANTS.DIFFERENT_DUE_DATE && (
                        <table className={classes.tableStyle}>
                            <caption className={classes.tableCaption}>
                                {i18n.t('DUE_DATE_TABLE_CAPTION')}
                            </caption>
                            <thead>
                                <tr>
                                    <th>{i18n.t('REVIEWERS_CAPTION')}</th>

                                    <th>{i18n.t('DUE_DATE_CAPTION')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <Field
                                    name={`recipients`}
                                    type="checkbox"
                                    defaultChecked
                                    render={() => differentDueDateComponent(values, form)}
                                />
                            </tbody>
                        </table>
                    )}
                </div>
            ]
        },
        [
            classes,
            stepTwoError,
            initialValues,
            sameDate,
            radioBtnArray,
            sameDatePicker,
            differentDueDateComponent
        ]
    )
    const formRender = useCallback(
        (values, handleSubmit, form) => (
            <FormBody id="dueDatesformBody" onSubmit={handleSubmit}>
                <FormSpy
                    onChange={({ values }) => {
                        formSpySameDueDateOnChange(values, form, dateFormatInput)
                    }}
                />
                <RadioButtonGroupField
                    name={DATE_STEPS.DUE_DATE_FIELDS}
                    defaultSelected={DATE_STEPS.NO_DUE_DATE}>
                    {() => memoisedRadioBtn(values, form)}
                </RadioButtonGroupField>
            </FormBody>
        ),

        [memoisedRadioBtn, dateFormatInput]
    )
    return (
        <MuiThemeProvider theme={materialAtlasTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <div>
                    <Divider className={classes.divider} />
                    <Typography variant="h6" className={classes.generalHeaderTag}>
                        {i18n.t('REVIEWER_DUE_DATE')}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.reviewerDiscription}>
                        {i18n.t('REVIEWER_DUE_DATE_DESCRIPTION')}
                    </Typography>
                    <Form
                        initialValues={initialValues}
                        onSubmit={onHandleSubmitForm}
                        render={({ values, handleSubmit, form }) =>
                            formRender(values, handleSubmit, form)
                        }></Form>
                </div>
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    )
}

export default DueDateForm
