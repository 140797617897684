import { createSelector } from 'reselect'

const selectorDataState = (state) => {
    return state.dataState
}

const selectorCommunicationState = (state) => {
    return state.communicationState
}

const selectorControlState = (state) => {
    return state.controlState
}

const selectorSectionOpened = (state, id) => {
    //Sections expanded by default per AC
    if (typeof state.controlState.sectionOpenStates.get(id) === 'undefined') {
        return true
    }
    return state.controlState.sectionOpenStates.get(id)
}

const selectorSection = (state, id) => {
    let result = state.dataState.minutesSections.find((c) => c.id === id || c.tempId === id)
    if (!result) {
        //Special case: we we first saved new item so now we return it not by blank string id but by the fact it is only item
        return null
    }

    return result
}

export default {
    isSavingSection: createSelector(
        selectorCommunicationState,
        (communicationState) => communicationState.isSavingSection
    ),
    isExportingMinutes: createSelector(
        selectorCommunicationState,
        (communicationState) => communicationState.isExportingMinutes
    ),
    deletingSectionId: createSelector(
        selectorCommunicationState,
        (communicationState) => communicationState.deletingSectionId
    ),
    isMinutesExpanded: createSelector(
        selectorControlState,
        (controlState) => controlState.isMinutesExpanded
    ),
    isEditingAction: createSelector(
        selectorControlState,
        (controlState) => controlState.isEditingAction
    ),
    currentMinuteItem: createSelector(
        selectorDataState,
        (dataState) => dataState.currentMinuteItem
    ),
    committeeId: createSelector(selectorDataState, (dataState) => dataState.committeeId),
    minutesSections: createSelector(selectorDataState, (dataState) => dataState.minutesSections),
    isSectionOpened: createSelector(selectorSectionOpened, (result) => result),
    section: createSelector(selectorSection, (result) => result),
    selectedSection: createSelector(
        selectorControlState,
        (controlState) => controlState.selectedSection
    ),
    minutesActions: createSelector(selectorDataState, (dataState) => dataState.minutesActions),
    editedAction: createSelector(selectorDataState, (dataState) => dataState.editedAction),
    editedBlock: createSelector(selectorDataState, (dataState) => dataState.editedBlock),
    lastExport: createSelector(selectorDataState, (dataState) => dataState.lastExport),
    lastExportType: createSelector(selectorDataState, (dataState) => dataState.lastExportType),
    dateFormat: createSelector(selectorDataState, (dataState) => dataState.dateFormat),
    timeFormat: createSelector(selectorDataState, (dataState) => dataState.timeFormat),
    collapseAllVisible: createSelector(
        selectorControlState,
        (controlState) => controlState.collapseAllVisible
    ),
    selectedTabIndex: createSelector(
        selectorControlState,
        (controlState) => controlState.selectedTabIndex
    ),
    selectedActionItem: createSelector(
        selectorControlState,
        (controlState) => controlState.selectedActionItem
    ),
    textFormattingActionPending: createSelector(
        selectorControlState,
        (controlState) => controlState.textFormattingActionPending
    ),
    blockFormattingAction: createSelector(
        selectorControlState,
        (controlState) => controlState.blockFormattingAction
    ),
    textFormattingRequestUpdatePanelState: createSelector(
        selectorControlState,
        (controlState) => controlState.textFormattingRequestUpdatePanelState
    ),
    currentSelectedTab: createSelector(
        selectorControlState,
        (controlState) => controlState.currentSelectedTab
    ),
    showSideBar: createSelector(selectorControlState, (controlState) => controlState.showSideBar)
}
