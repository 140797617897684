import { exchangeUuidToken } from 'businesslayer/api/identity'
import { useEffect, useState } from 'react'
import { doLogin, doSwitchSite } from 'businesslayer/sso/SsoManagerFactoryService'
import {
    getSessionStorageItem,
    removeSessionStorageItem,
    setSessionStorageItem
} from 'businesslayer/minutesLocalStore'

type SuccesURLState = {
    error: any
    url: string
    site_name: string
}
type FailURL = {
    source: any
    status: any
    error: any
    url: string
    message: string
    site_name: string
}
export const useUuid = (uuid) => {
    const [urlData, setUrlData] = useState<SuccesURLState>({
        error: '',
        url: '',
        site_name: ''
    })
    const [errorMessage, setErrorMessage] = useState<FailURL>({
        source: '',
        status: '',
        error: '',
        url: '',
        message: '',
        site_name: ''
    })
    const [isIpad, setIsIpad] = useState(false)

    const fetchApi = async (uuid) => {
        try {
            const data = await exchangeUuidToken(uuid, exchangeUuidToken.bind(null, uuid))
            setUrlData(data)
        } catch (err) {
            const errordata = await err.response.json()
            const isNotification = getSessionStorageItem('isNotification')
            const hasNotification = isNotification === 'true'

            setErrorMessage({
                source: errordata.source,
                status: err.response.status,
                error: err.response.statusText,
                url: errordata.parent_url,
                message: errordata.message,
                site_name: hasNotification ? errordata.site_name : ''
            })
        }
    }

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase()

        // Check for iPad using userAgent
        if (/ipad/.test(userAgent) || (/macintosh/.test(userAgent) && 'ontouchend' in document)) {
            setIsIpad(true)
        }
        if (!!uuid) {
            fetchApi(uuid)
            setSessionStorageItem('uuid', uuid)
            setSessionStorageItem('isNotification', 'true')
            setSessionStorageItem('parentPlatform', 'boardswebadmin')
        } else {
            removeSessionStorageItem('uuid')
        }
    }, [uuid])

    if (urlData.url) {
        const redirectUrl = new URL(urlData.url)
        setSessionStorageItem('targetSiteName', urlData.site_name)
        window.location.href = `${redirectUrl.pathname}`
    }
    if (errorMessage.status === 403) {
        // boards redirect in case of ipad login
        if (!isIpad) {
            setSessionStorageItem('targetSiteName', errorMessage.site_name)
            // If the site name is available but the token is not, navigate directly to that site. Otherwise, proceed with the normal login process, allowing the user to select the site.
            if (errorMessage.site_name !== '' && !getSessionStorageItem('sessionId')) {
                doSwitchSite(errorMessage.site_name)
            } else {
                doLogin()
            }
        } else {
            window.location.href = errorMessage.url
        }
    }

    return {
        errorMessage
    }
}
