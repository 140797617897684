import React, { useEffect, useState } from 'react'

import authenticationManager from '../../businesslayer/authenticationManager'

//#region Components
import { DocumentDetailsLayout } from 'assets/styles/LayoutConstants'
import SignOutIcon from 'assets/icons/SignOutIcon'
import DividerIcon from 'assets/icons/DividerIcon'

//#endregion

//#region Redux
import { connect } from 'react-redux'
import selectors from '../../selectors/minuteTakerSelectors'
import editorSelectors from '../../selectors/minuteEditorSelectors'
import { css, ClassNames } from '@emotion/react'
import styled from '@emotion/styled'
import { makeStyles } from '@material-ui/core'
import { isChatBotEnable } from 'common/util/config'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import { Box, Button } from '@material-ui/core'
import { ColorBaseMulberryBlue } from 'assets/styles/variables'
import AiHelpChatBox from 'minutes-ai-help-chat/components/AiHelpChatBox'
import i18n from 'common/util/internationalization'
import { doLogout } from 'businesslayer/sso/SsoManagerFactoryService'
import { getSessionStorageItem } from 'businesslayer/minutesLocalStore'

//#endregion

//#region Styles

export const StyledHeader = styled('div')<{ height: number }>`
    display: flex;
    position: sticky;
    height: ${({ height }) => height}px;
    padding-left: ${({ height }) => height}px;
    padding-right: ${({ height }) => height}px;
    background-color: #2f3b4d;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 1000;
`

export const TitleStyle = styled('p')`
    font-size: 18px;
    color: #ffffff;
    line-height: 24px;
    font-weight: 600;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex-grow: 1;
`

const logoContainerClass = (height) => {
    return css`
        width: ${height / 2}px;
        height: ${height / 2}px;
        position: absolute;
        left: ${height / 4}px;
        top: ${height / 4}px;
        @media (max-width: 1367px) {
            left: ${height}px;
        }
    `
}

const aiChatBotContainerClass = (height) => {
    return css`
        width: ${height / 2}px;
        height: ${height / 2}px;
        right: ${height}px;
        top: ${height / 4}px;
    `
}

const logoStyleClass = (height) => {
    return css`
        position: relative;
        height: ${height / 2}px;
    `
}
const useStyles = makeStyles((theme) => ({
    flexCss: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginLeft: '2.5%',
        [theme.breakpoints.down('lg')]: {
            marginLeft: '50px'
        }
    },
    diligentHelpButton: {
        backgroundColor: '#2f3b4d',
        lineHeight: '3.2rem',
        minWidth: '34px',
        height: '34px',
        borderRadius: '2px',
        padding: '0',
        color: 'white',
        position: 'relative',
        cursor: 'pointer',
        '&:focus, &:active': {
            outline: `2px solid ${ColorBaseMulberryBlue}`
        }
    },
    line1: {
        transform: 'rotateX(-70deg) rotateY(0) rotate(0)',
        animation: '$line1Animation 12s cubic-bezier(0.83, 0.82, 0.82, 0.99) infinite'
    },
    line2: {
        transform: 'rotateX(-40deg) rotateY(60deg) rotate(-44deg)',
        animation: '$line2Animation 12s cubic-bezier(0.83, 0.82, 0.82, 0.99) infinite'
    },
    line3: {
        transform: 'rotateX(50deg) rotateY(50deg) rotate(180deg)',
        animation: '$line3Animation 12s cubic-bezier(0.83, 0.82, 0.82, 0.99) infinite'
    },
    line: {
        borderRadius: '50%',
        border: '3px solid white',
        borderBottomColor: 'transparent',
        width: '21px',
        height: '21px',
        position: 'absolute',
        top: '4px',
        left: '5px'
    },
    logoutBtn: {
        cursor: 'pointer'
    }
}))
//#endregion

//#region Props

type Props = {
    width: number
    dateFormat: string
    currentMinuteItem: any
    currentMinuteItemInEditor: any
    title: string
}

//#endregion

//#region Implementation
//Note: this component listens to save related things
//happening in other places so needs a reference to many other components
const Header = (props: Props) => {
    const [isIpad, setIsIpad] = useState(false)

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase()

        // Check for iPad using userAgent
        if (/ipad/.test(userAgent) || (/macintosh/.test(userAgent) && 'ontouchend' in document)) {
            setIsIpad(true)
        }
    }, [])
    const height = DocumentDetailsLayout.header.height

    const minuteItemHasInvalidLogo = (currentMinuteItem: {
        attributes: { logo: any }
    }): boolean => {
        return (
            !currentMinuteItem ||
            !currentMinuteItem.attributes.logo ||
            !currentMinuteItem.attributes.logo
        )
    }

    const getLogo = () => {
        const { currentMinuteItem } = props

        if (minuteItemHasInvalidLogo(currentMinuteItem)) {
            return null
        }

        const logo = currentMinuteItem ? currentMinuteItem.attributes.logo : ''

        const logoHeight = height || 80
        const url = `/api${logo}&session_token=${authenticationManager.getRawToken()}`

        return (
            <ClassNames>
                {({ css }) => (
                    <div
                        className={css`
                            ${logoContainerClass(logoHeight)}
                        `}>
                        <img
                            className={css`
                                ${logoStyleClass(logoHeight)}
                            `}
                            src={url}
                            alt=""
                        />
                    </div>
                )}
            </ClassNames>
        )
    }

    const classes = useStyles()
    const platform = transientStorageManager.platform || ''
    const isValidPlatform = isChatBotEnable(transientStorageManager?.ai_chat_bot, platform)
    const site_logo = transientStorageManager?.logo_blob
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const chatBotHeight = height
    const logoHeight = height || 80
    const isTakerPath = window.location.pathname.includes('taker')
    const hasMinuteLogo =
        props?.currentMinuteItem?.attributes?.logo ||
        props?.currentMinuteItem?.attributes?.logo === null
    const logo =
        isTakerPath && hasMinuteLogo
            ? getLogo()
            : site_logo && (
                  <ClassNames>
                      {({ css }) => (
                          <div
                              className={css`
                                  ${logoContainerClass(logoHeight)}
                              `}>
                              <img
                                  className={css`
                                      ${logoStyleClass(logoHeight)}
                                  `}
                                  src={`data:image/jpeg;base64, ${site_logo}`}
                                  width="auto"
                                  height="32"
                                  alt={i18n.t('SITE_LOGO')}
                              />
                          </div>
                      )}
                  </ClassNames>
              )
    const parentPlatform = getSessionStorageItem('parentPlatform')
    return (
        <StyledHeader height={height}>
            <div className={classes.flexCss}>
                {logo}
                <TitleStyle>{props.title}</TitleStyle>
                {isValidPlatform && (
                    <ClassNames>
                        {({ css }) => (
                            <Box
                                className={css`
                                    ${aiChatBotContainerClass(chatBotHeight)}
                                `}>
                                <Button
                                    disableFocusRipple
                                    disableRipple
                                    onClick={() => setIsOpen(!isOpen)}
                                    data-analytics="AI-Chat"
                                    title={i18n.t('CHAT_BOT_TITLE')}
                                    className={classes.diligentHelpButton}>
                                    <section className="icon">
                                        <section
                                            className={`${classes.line} ${classes.line1}`}></section>
                                        <section
                                            className={`${classes.line} ${classes.line2}`}></section>
                                        <section
                                            className={`${classes.line} ${classes.line3}`}></section>
                                    </section>
                                </Button>
                                {<AiHelpChatBox isOpen={isOpen} setIsOpen={setIsOpen} />}
                            </Box>
                        )}
                    </ClassNames>
                )}

                {!isIpad &&
                (transientStorageManager.platform === 'boards' ||
                    (parentPlatform &&
                        transientStorageManager.platform !== 'boardsios' &&
                        ((transientStorageManager.platform !== 'boardeffect' &&
                            window._env_.ENABLE_OIDC_LOGIN_BOARD === 'true') ||
                            (transientStorageManager.platform === 'boardeffect' &&
                                window._env_.ENABLE_OIDC_LOGIN_BOARD_EFFECT === 'true')))) ? (
                    <>
                        <DividerIcon />
                        <Button
                            type="button"
                            aria-live="polite"
                            aria-label={i18n.t('SIGN_OUT')}
                            disableFocusRipple
                            disableRipple
                            onClick={doLogout}
                            title={i18n.t('SIGN_OUT')}
                            className={classes.diligentHelpButton}>
                            <SignOutIcon className={classes.logoutBtn} />
                        </Button>
                    </>
                ) : null}
            </div>
        </StyledHeader>
    )
}
//#endregion

//#region Export / Redux Bindings

const mapStateToProps = (state, _) => {
    return {
        currentMinuteItem: selectors.currentMinuteItem(state.minuteTakerReducer),
        dateFormat: selectors.dateFormat(state.minuteTakerReducer),
        currentMinuteItemInEditor: editorSelectors.editedItem(state.minuteEditorReducer)
    }
}

export default connect(mapStateToProps)(Header)

//#endregion
