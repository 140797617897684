import React, { Component } from 'react'
import ConfirmationDialog from '../../../shared/ConfirmationDialog'
import styled from '@emotion/styled'
//#region Components

import { translate } from 'components/shared/internationalization'
import i18n from 'i18next'
import { TrashCanDeleteIcon as DeleteActionItemIcon } from 'assets/icons/TrashCanDeleteIcon'
import ActionsItemEditForm from './ActionItemEditForm'
import {
    ColorBaseBlueMedium,
    ColorBaseWhite,
    FontWeightLightBold,
    ColorBaseMulberryBlue
} from 'assets/styles/variables'
import {
    Dialog,
    DialogActions,
    AddActionEditActionButton,
    IconButton,
    NewDialogContent,
    NewDialogTitle
} from 'components/shared/StyledComponents'
import { deleteAction } from 'businesslayer/api/actions'
import { getMinutesDetail } from 'businesslayer/api/minutes'
//#endregion

//#region Redux

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions } from '../../../../reducers/minuteTakerReducer'
import { actions as editorActions } from '../../../../reducers/actionEditorReducer'
import { actions as actionEditorReducer } from 'reducers/actionEditorReducer'
import selectors from '../../../../selectors/minuteTakerSelectors'
import editorSelectors from '../../../../selectors/actionEditorSelectors'
import { ClassNames, css } from '@emotion/react'
import { AtlasIcon } from 'web-components/atlas-icon'
import { atlasFontFamily } from 'components/atlas-components/typography'
import { AtlasButtonOld } from 'components/atlas-material/button'

//#endregion

//#region Props

type Props = {
    actions: {
        removeAction: (...args: any[]) => void
        resetEditedAction: () => void
        addSection: () => void
        editContentAction: (...args: any[]) => void
        updateTakerListAction: (...args: any[]) => void
        ensureSectionExpanded: (...args: any[]) => void
        saveExistingAction: (...args: any[]) => void
        appendTakerListAction: (...args: any[]) => void
        saveNewAction: (...args: any[]) => void
        editSectionAction: (...args: any[]) => void
        prepareSaveAction: (...args: any[]) => void
        validateSectionAction: (...args: any[]) => void
        createSectionAction: (...args: any[]) => void
        loadContacts: (...args: any[]) => void
        fetchMinuteItemFulfilled: (...args: any[]) => void
    }

    savedAction: any
    saveComplete: boolean
    readyToSave: boolean
    action: any
    sections: Array<any>
    selectedSection: any
    currentMinuteItem: any
    isEditingAction: boolean
    editedAction: any
    block: any
    allContacts: Array<any>
    dateInputMask: string
    dateFormat: string
}

//#endregion

//#region Implementation

const makeDialogActions = ({
    editedAction,
    cancelButtonDisabled,
    showDeleteButton = true,
    okButtonDisabled,
    onClose,
    onSave,
    onDelete
}) => {
    const cancelButton = (
        <AddActionEditActionButton
            key="actionCancel"
            disableFocusRipple
            disableRipple
            disabled={cancelButtonDisabled}
            onClick={onClose}>
            {translate('CANCEL')}
        </AddActionEditActionButton>
    )

    const deleteButton = (
        <ClassNames key="actionDeleteCss">
            {({ css }) => (
                <button
                    key="actionDelete"
                    data-analytics="MM-DeleteAction"
                    className={css`
                        ${DeleteButton}
                    `}
                    onClick={onDelete}>
                    {<StyledDeleteActionItemIcon />}
                    {translate('DELETE_ACTION_CTA')}
                </button>
            )}
        </ClassNames>
    )

    const okButton = (
        <StyledAtlasButtonOld
            key="actionSave"
            disabled={okButtonDisabled}
            onClick={onSave}
            disableFocusRipple>
            {editedAction ? translate('SAVE') : translate('ADD')}
        </StyledAtlasButtonOld>
    )

    return [showDeleteButton && deleteButton, cancelButton, okButton]
}

class ActionItemEditDialog extends Component<Props> {
    actionText?: HTMLElement
    assigneesInput?: any
    datePicker?: any

    state = {
        assigneeError: '',
        visible: false,
        confirmedAction: null,
        isSavable: false
    }
    componentDidUpdate() {
        if (this.state.visible) {
            return
        }
        //That is a visual trick to tackle problem of dialog changing its position after being loaded.
        //We let it load and reposition but show it after reporsition. That is around 200 msec for every thing.
        //We also make sure that overlay shown right away to make impression of fast launch.
        //I.e. overlay without dialog will stay for about 200msec and then dialog will transition in from 0 opacity.
        setTimeout(() => {
            this.setState({ visible: true })
        }, 200)
    }

    private handleSaveComplete = (nextSavedAction: any) => {
        if (nextSavedAction) {
            //isUpdated - flag to distinct updated vs created
            if (nextSavedAction.isUpdated) {
                delete nextSavedAction.isUpdated

                //We saved action to db, now it is time update its text in the editor

                //Also make sure actions grid gets fresh object
                this.props.actions.updateTakerListAction(nextSavedAction)
            } else {
                //If new action created we have to make sure the section it was added to, is expanded
                this.props.actions.ensureSectionExpanded(nextSavedAction.minutesSectionId)

                //As we potentionally expanded hidden section on the step above,
                //We have to wait till reducer gets new set of states so the action would be added properly
                //It could be via another handling in UNSAFE_componentWillReceiveProps, but code is already complex
                //So simpler approach is used in here.
                setTimeout(() => {
                    //Also make sure actions grid gets newly created object
                    this.props.actions.appendTakerListAction(nextSavedAction)
                }, 50)
            }
        }
    }

    private completeSavingAction = (action: any) => {
        if (action) {
            if (action.id) {
                this.props.actions.saveExistingAction(action)
            } else {
                //If minutesSectionId = -1 it means we have to create new section first and then save action
                if (`${action.minutesSectionId}` === '-1') {
                    //Note: we dont save new action here as we have to wait till
                    //process of adding section is done (*this is option when user choosees to create new section right
                    //from the add action dialog)
                    this.props.actions.addSection()
                } else {
                    this.props.actions.saveNewAction(action)
                }
            }
        }
    }
    private saveActionToNewlyAddedSection = (nextSections: Array<any>, action: any) => {
        const lastSection = nextSections[nextSections.length - 1]

        if (lastSection && lastSection.id && lastSection.tempId) {
            if (`${action.minutesSectionId}` === '-1') {
                action.minutesSectionId = lastSection.id
                this.props.actions.saveNewAction(action)
            }
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const { saveComplete, readyToSave, action, sections } = this.props

        if (nextProps.saveComplete && saveComplete !== nextProps.saveComplete) {
            //After save cleanups
            this.props.actions.resetEditedAction()
            this.handleSaveComplete(nextProps.savedAction)
            return
        }

        if (nextProps.readyToSave && readyToSave !== nextProps.readyToSave) {
            //Ready to save means validation is ok and user pressed save buttom somewhere alone the line
            this.completeSavingAction(action)
        }

        if (nextProps.readyToSave && sections && nextProps.sections) {
            //This should complete saving action if section was created on prev step
            this.saveActionToNewlyAddedSection(nextProps.sections, action)
        }
    }
    componentDidMount() {
        const { editedAction, currentMinuteItem } = this.props

        if (editedAction && editedAction.assignees) {
            editedAction.assignees.forEach((c) => (c.isInverted = false))
        }

        if (editedAction) {
            this.props.actions.editSectionAction(editedAction)
        } else {
            if (currentMinuteItem) {
                this.props.actions.createSectionAction(currentMinuteItem.id)
            }
        }

        this.props.actions.loadContacts(true)
    }

    private onDeleteAction = () => {
        this.setState({
            confirmedAction: async () => {
                const { action, currentMinuteItem } = this.props
                await deleteAction(
                    currentMinuteItem.id,
                    action.id,
                    deleteAction.bind(null, currentMinuteItem.id, action.id)
                )
                this.props.actions.removeAction(action.id)

                const minutesDetails = await getMinutesDetail(currentMinuteItem.id, null, true)
                this.props.actions.fetchMinuteItemFulfilled(minutesDetails)
                this.props.actions.resetEditedAction()
            }
        })
    }

    private onCloseDialog = () => {
        const { readyToSave, action } = this.props
        if (readyToSave && !action.id) {
            //We are in a process of saving
            return
        }
        this.props.actions.resetEditedAction()
    }

    private onSave = () => {
        // TODO: Remove when ChipInput is replaced during Material-UI
        // Hacky way to let ChipInput onBlur create chip before saving
        setTimeout(() => {
            //this.createOrphanAssignee()

            const { action, readyToSave } = this.props

            if (readyToSave && `${action.minutesSectionId}` === '-1') {
                return
            }

            // Submit logic for final-form
            const form = document.getElementById('editActionsForm')
            if (form) form.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }))

            //!!hideDialog && hideDialog('ExportActionsDialog')

            //Logic is the same as in create minutes dialog.
            //1. We call reducer to make validation and determine if we are ready to hit save
            //2. If we are, we call reducer to make saving
            //This 2-stage saving is done so UI can disable itself just
            //before but after validation for saving is done
            //this.props.actions.prepareSaveAction(action)
        }, 200)
    }

    private handleFormSubmit = (action) => {
        this.props.actions.prepareSaveAction(action)
    }

    handleFormValidate = (valid: boolean) => {
        const myThis = this
        setTimeout(function () {
            if (myThis.state.isSavable !== valid) {
                myThis.setState({ isSavable: valid })
            }
        }, 10)
    }
    getDatefnsFormat = (dateFormat) => {
        // fix capitalization for date-fns format
        return dateFormat.replace(/[DY]/g, function (c: string) {
            return c.toLowerCase()
        })
    }
    private renderAddActionDialog = () => {
        const { action, readyToSave, editedAction, dateInputMask } = this.props
        const dialogTitle = editedAction ? translate('EDIT_ACTION') : translate('ADD_ACTION_ITEM')
        const showDeleteButton = !!action.id

        const dialogActions = makeDialogActions({
            editedAction,
            cancelButtonDisabled: readyToSave,
            okButtonDisabled: !this.state.isSavable,
            showDeleteButton,
            onSave: this.onSave,
            onClose: this.onCloseDialog,
            onDelete: this.onDeleteAction
        })

        return (
            <ClassNames>
                {({ css }) => (
                    <Dialog
                        className={css`
                            .MuiPaper-root.MuiDialog-paper {
                                max-width: 1000px;
                                max-height: 100%;
                            }
                        `}
                        aria-labelledby={i18n.t('ADD_ACTION')}
                        open={true}
                        maxWidth="md"
                        onClose={this.onCloseDialog}
                        disableRestoreFocus>
                        <NewDialogTitle id={i18n.t('ADD_ACTION')}>
                            <div>{dialogTitle}</div>
                            <IconButton
                                tabIndex={0}
                                disableFocusRipple
                                disableRipple
                                className={css`
                                    ${AtlasButtonCss}
                                `}
                                onClick={this.onCloseDialog}>
                                <AtlasIcon
                                    name="close"
                                    size={24}
                                    data-testid="AtlasModal_CloseIcon"
                                />
                            </IconButton>
                        </NewDialogTitle>

                        <NewDialogContent>
                            <ActionsItemEditForm
                                action={action}
                                allContacts={this.props.allContacts}
                                dateInputMask={this.getDatefnsFormat(dateInputMask)}
                                sections={this.props.sections}
                                selectedSection={this.props.selectedSection}
                                onActionItemSubmit={(action) => {
                                    this.handleFormSubmit(action)
                                }}
                                onValidate={(isValid) => {
                                    this.handleFormValidate(isValid)
                                }}
                            />
                        </NewDialogContent>
                        <DialogActions>{dialogActions}</DialogActions>
                    </Dialog>
                )}
            </ClassNames>
        )
    }

    private renderConfirmedAction = () => {
        const { confirmedAction } = this.state

        if (!confirmedAction) {
            return null
        }

        return (
            <ConfirmationDialog
                message={i18n.t('DELETE_ACTION_MESSAGE')}
                title={i18n.t('DELETE_ACTION_TITLE')}
                onConfirm={confirmedAction}
                onCancel={() => this.setState({ confirmedAction: null })}
                confirmLabel={i18n.t('DELETE')}
            />
        )
    }

    render() {
        const { isEditingAction, action } = this.props
        const { confirmedAction } = this.state

        if (!isEditingAction || !action) {
            return null
        }

        if (confirmedAction) {
            return <div>{this.renderConfirmedAction()}</div>
        }

        return <div>{this.renderAddActionDialog()}</div>
    }
}

//#endregion

//#region Styles
export const StyledAtlasButtonOld = styled(AtlasButtonOld)`
    background-color: ${(props) => (props.disabled ? '#E6E6E6' : '#455D82')};
    color: ${(props) => (props.disabled ? '#B3B3B3' : 'white')};
    border-radius: 2px;
    span {
        text-transform: none;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: ${FontWeightLightBold};
        font-size: 16px;
        line-height: 20px;
    }
    &:focus {
        outline: none;
        box-shadow: 0 0 0 1px ${ColorBaseWhite}, 0 0 0 3px ${ColorBaseMulberryBlue};
        border-radius: 2px;
    }
`
export const getDialogTitleStyle = () => {
    return {
        backgroundColor: ColorBaseBlueMedium,
        color: ColorBaseWhite,
        height: 50,
        fontSize: '24px',
        padding: 0,
        paddingLeft: 35,
        lineHeight: '55px',
        fontWeight: '300',
        marginBottom: 0
    }
}

export const StyledDeleteActionItemIcon = styled(DeleteActionItemIcon)`
    fill: ${ColorBaseWhite};
    top: 8px;
    bottom: 8px;
    left: 12px;
    margin-right: 12px;
    vertical-align: middle;
    width: 24px;
    height: 24px;
`
export const DeleteButton = css`
    background-color: #af292e;
    height: 40px;
    color: ${ColorBaseWhite};
    font-family: ${atlasFontFamily};
    font-style: normal;
    font-weight: ${FontWeightLightBold};
    font-size: 16px;
    border: none;
    mix-blend-mode: normal;
    border-radius: 2px;
    margin-right: auto;
    text-align: center;
    &:focus {
        outline: none;
        box-shadow: 0 0 0 1px ${ColorBaseWhite}, 0 0 0 3px ${ColorBaseMulberryBlue};
        border-radius: 2px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
`
export const AtlasButtonCss = css`
    margin-right: 0px;
    width: 40px;
    height: 40px;
    &:focus {
        border: 2px solid ${ColorBaseMulberryBlue};
        border-radius: 4px;
    }
`

//#endregion

//#endregion Export / Redux Bindings

const mapStateToProps = (state, _) => {
    return {
        isEditingAction: selectors.isEditingAction(state.minuteTakerReducer),
        sections: selectors.minutesSections(state.minuteTakerReducer),
        currentMinuteItem: selectors.currentMinuteItem(state.minuteTakerReducer),
        selectedSection: selectors.selectedSection(state.minuteTakerReducer),
        editedAction: selectors.editedAction(state.minuteTakerReducer),
        block: selectors.editedBlock(state.minuteTakerReducer),
        action: editorSelectors.currentAction(state.actionEditorReducer),
        allContacts: editorSelectors.allContacts(state.actionEditorReducer),
        readyToSave: editorSelectors.readyToSave(state.actionEditorReducer),
        saveComplete: editorSelectors.saveComplete(state.actionEditorReducer),
        savedAction: editorSelectors.savedAction(state.actionEditorReducer),
        dateFormat: editorSelectors.dateFormat(state.actionEditorReducer),
        dateInputMask: editorSelectors.dateInputMask(state.actionEditorReducer)
    }
}

const mapDispatchToProps = (dispatch) => {
    const {
        removeAction,
        resetEditedAction,
        addSection,
        updateTakerListAction,
        ensureSectionExpanded,
        saveExistingAction,
        appendTakerListAction,
        saveNewAction,
        editSectionAction,
        prepareSaveAction,
        validateSectionAction,
        createSectionAction,
        loadContacts,
        fetchMinuteItemFulfilled
    } = { ...actions, ...editorActions, ...actionEditorReducer }

    return {
        actions: bindActionCreators(
            {
                removeAction,
                resetEditedAction,
                addSection,
                updateTakerListAction,
                ensureSectionExpanded,
                saveExistingAction,
                appendTakerListAction,
                saveNewAction,
                editSectionAction,
                prepareSaveAction,
                validateSectionAction,
                createSectionAction,
                loadContacts,
                fetchMinuteItemFulfilled
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionItemEditDialog)
//#endregion
