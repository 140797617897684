import React, { useMemo } from 'react'
import { atlasTitleSmallEmphasis } from 'components/atlas-components/typography'
import { AtlasButton } from 'components/atlas-material/button'
import i18n from 'i18next'
import { Box, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

import ExportReportIcon from 'assets/icons/ExportReportIcon'
import ReactDataTable, { Column, DataRow } from '../shared/table/react-data-table'
import { ReportData, ReportResponseData } from './minutes-report-details'
import { formatDate } from 'common/formatters/date'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import { ColorBaseSkyBlue, ColorBaseWhite } from 'assets/styles/variables'

interface ReportContentViewProps extends Partial<ReportData> {
    totalRowCount: number | string
    exportReportClickHandler: () => void
    reportData: ReportResponseData
    isDownloading?: boolean
    preservedFormState: {
        startDate: string
        endDate: string
        committeeName?: string
    }
    disableExportReport: {
        isStateMatched: boolean
        isReportGenerated: boolean
    }
}
const columns = (COMMITEE_TITLE: string): Column[] => [
    {
        name: i18n.t('DIRECTOR'),
        selector: 'name',
        sortable: true
    },
    {
        name: COMMITEE_TITLE,
        selector: 'attended',
        sortable: true
    },
    {
        name: i18n.t('ATTENDANCE_PERCENTAGE'),
        selector: 'percentage',
        sortable: true,
        suffix: '%'
    }
]
const useStyles = makeStyles((theme: Theme) => ({
    reportHeaderContainer: {
        display: 'flex',
        height: '72px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '16px 24px',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px'
    },
    exportReportCls: {
        backgroundColor: '#455d82 !important',
        display: 'flex',
        color: '#fff',
        borderWidth: '0px',
        borderRadius: '2px',
        letterSpacing: '0.5px',
        textTransform: 'capitalize',
        padding: '8px 12px 8px 12px',
        '&:focus, &:active': {
            outline: 'none',
            boxShadow: `0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px ${ColorBaseSkyBlue}`,
            borderRadius: '2px'
        },
        '&:disabled': {
            backgroundColor: '#d7dde6 !important',
            color: '#fff'
        }
    },
    createMinutesTxtCls: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 20px',
        fontSize: '1rem',
        fontFamily: 'Source Sans Pro',
        lineHeight: '20px',
        fontWeight: 600
    },
    exportReportCss: {
        height: '24px',
        width: '24px'
    },
    reportBodyContainer: {
        padding: '24px'
    },
    reportTitleContainer: {},
    exportReportContainer: {},
    subTitleCss: {
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left',
        paddingLeft: '24px'
    },
    atlasTitleSmallEmphasis: atlasTitleSmallEmphasis,
    reportDetails: {
        width: 'auto',
        height: 'auto',
        display: 'grid',
        gridGap: '12px',
        paddingTop: '15px'
    }
}))
const ReportContentView: React.FC<ReportContentViewProps> = React.memo(
    ({
        totalRowCount,
        exportReportClickHandler,
        reportData,
        isDownloading,
        preservedFormState,
        disableExportReport
    }) => {
        const classes = useStyles()
        const { dateFormat } = transientStorageManager
        const disableExportButton =
            !disableExportReport.isStateMatched && disableExportReport.isReportGenerated
        const { startDate, endDate, columnsDef, tableData, meetingsCount } = useMemo(() => {
            const { committee_name, attendance_data, total_meeting_count } = reportData
            const { startDate, endDate, committeeName } = preservedFormState
            return {
                startDate: formatDate({
                    date: startDate,
                    dateFormat: dateFormat
                }),
                endDate: formatDate({
                    date: endDate,
                    dateFormat: dateFormat
                }),
                tableData: attendance_data,
                columnsDef: columns(committee_name ?? committeeName),
                meetingsCount: total_meeting_count
            }
        }, [reportData, preservedFormState, dateFormat])
        return (
            <Box>
                <Box className={classes.reportHeaderContainer}>
                    <Box className={classes.reportTitleContainer}>
                        <span className={classes.atlasTitleSmallEmphasis}>
                            {i18n.t('REPORT_RESULT')}
                        </span>
                    </Box>
                    <Box className={classes.exportReportContainer}>
                        <AtlasButton
                            role="button"
                            tabIndex={0}
                            data-testid=""
                            data-analytics="MM-AttendanceReporting-ExportInExcel"
                            disableRipple
                            className={classes.exportReportCls}
                            disabled={isDownloading || disableExportButton}
                            onClick={exportReportClickHandler}>
                            <ExportReportIcon
                                className={classes.exportReportCss}
                                title={i18n.t('EXPORT')}
                            />
                            <span className={classes.createMinutesTxtCls}>
                                {i18n.t('EXPORT_REPORT')}
                            </span>
                        </AtlasButton>
                    </Box>
                </Box>
                <Box className={classes.reportDetails}>
                    <Typography className={classes.subTitleCss}>{`${i18n.t(
                        'DATE_RANGE'
                    )}: ${startDate} - ${endDate}`}</Typography>
                    <Typography className={classes.subTitleCss}>
                        {`${i18n.t('TOTAL_NO_OF_MEETINGS')} ${meetingsCount}`}
                    </Typography>
                    <Typography className={classes.subTitleCss}>
                        {`${i18n.t('TOTAL_NO_OF_ATTENDEES')} ${totalRowCount}`}
                    </Typography>
                </Box>
                <Box className={classes.reportBodyContainer}>
                    <ReactDataTable
                        columns={columnsDef as any}
                        tableData={(tableData as unknown) as DataRow[]}
                        defaultSortKey="attended"
                    />
                </Box>
            </Box>
        )
    }
)
export default ReportContentView
